import React, { Component } from "react";
import {
  convertDateFormat,
  capitalizeFirstLetter,
} from "../../utils/helperFunctions";
import { MDBBtn, MDBDataTable } from "mdbreact";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Pagination from "../Common/Pagination";
import { convertToFahrenheit } from "../../utils/celsiusToFahrenheit";

const columns = [
  {
    label: "Profile Name",
    field: "profileName",
    sort: "asc",
    width: 200,
  },
  {
    label: "Min Temp",
    field: "minTemp",
    sort: "asc",
    width: 100,
  },
  {
    label: "Max Temp",
    field: "maxTemp",
    sort: "asc",
    width: 100,
  },
  {
    label: "Min Humidity",
    field: "minHumidity",
    sort: "asc",
    width: 100,
  },
  {
    label: "Max Humidity",
    field: "maxHumidity",
    sort: "asc",
    width: 100,
  },
  {
    label: "Min CO₂",
    field: "minCarbon",
    sort: "asc",
    width: 100,
  },
  {
    label: "Max CO₂",
    field: "maxCarbon",
    sort: "asc",
    width: 100,
  },
  {
    label: "Spill Sensor",
    field: "waterEnabled",
    sort: "asc",
    width: 100,
  },
  {
    label: "Status",
    field: "enable",
    sort: "asc",
    width: 100,
  },
  {
    label: "Action",
    field: "action",
    width: 80,
  },
];

class profileTable extends Component {
  state = {
    loadTable: false,
    alertProfileList: [],
    pagination: {},
  };

  componentDidMount() {
    const { profileList } = this.props;
    if (profileList && profileList.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profileList !== this.props.profileList &&
      this.props.profileList &&
      this.props.profileList.length
    ) {
      this.showTable(true);
    }
  }

  addSymbol = (symbol, text) => {
    return text + symbol;
  };

  showTable = (loadTable) => {
    if (loadTable) {
      const {
        profileList,
        editProfile,
        deleteProfile,
        pagination,
        temperatureUnit,
      } = this.props;
      const rows = profileList.map((profile, index) => {
        let profileRow = { ...profile };
        const celcius = temperatureUnit === "Celsius" ? "°C" : "°F";
        const percentage = "%";

        profileRow.minTemp = !profileRow.tempEnabled
          ? "N/A"
          : temperatureUnit === "Celsius"
          ? profileRow.minTemp.toFixed(1) + celcius
          : convertToFahrenheit(profileRow.minTemp).toFixed(1) + celcius;
        profileRow.maxTemp = !profileRow.tempEnabled
          ? "N/A"
          : temperatureUnit === "Celsius"
          ? profileRow.maxTemp.toFixed(1) + celcius
          : convertToFahrenheit(profileRow.maxTemp).toFixed(1) + celcius;
        profileRow.minHumidity = !profileRow.humidityEnabled
          ? "N/A"
          : profileRow.minHumidity + percentage;
        profileRow.maxHumidity = !profileRow.humidityEnabled
          ? "N/A"
          : profileRow.maxHumidity + percentage;
        profileRow.minCarbon = !profileRow.carbonEnabled
          ? "N/A"
          : profileRow.minCarbon + percentage;
        profileRow.maxCarbon = !profileRow.carbonEnabled
          ? "N/A"
          : profileRow.maxCarbon + percentage;
        profileRow.enable = profileRow.enable ? "Enabled" : "Disabled";
        profileRow.waterEnabled = profileRow.waterEnabled
          ? "Enabled"
          : "Disabled";
        {
          this.props.userRole === "super_user" &&
            (profileRow.action = (
              <>
                <MDBBtn
                  onClick={deleteProfile.bind(this, profileRow.profileId)}
                  tag="a"
                  rounded
                  size="sm"
                  className="px-2 transparentBtn"
                >
                  <i className="fas fa-trash mt-0"></i>
                </MDBBtn>

                <MDBBtn
                  onClick={editProfile.bind(this, profileRow)}
                  tag="a"
                  rounded
                  size="sm"
                  className="px-2 transparentBtn"
                >
                  <i className=" fas fa-pencil-alt mt-0"></i>
                </MDBBtn>
              </>
            ));
        }
        return profileRow;
      });
      this.setState({
        loadTable,
        alertProfileList: {
          columns: columns,
          rows: rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { changePage } = this.props;
    const { loadTable, alertProfileList, pagination } = this.state;

    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort recentAlarmTable border-0"
              bordered
              data={alertProfileList}
              paging={false}
              scrollX
            />
            {/* <Row className="mt-3 mb-3">
              <Col lg={6}>
                <label>
                  Showing {pagination.currentPage * 10 - 9} to{" "}
                  {pagination.currentPage * 10} of all results
                </label>
              </Col>
              <Col lg={6} className="text-right">
                <Pagination
                  pagination={pagination}
                  changePage={(page) => changePage(page)}
                ></Pagination>
              </Col>
            </Row> */}
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(profileTable);
