import React, { memo } from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import "./index.styles.scss";

class Status extends React.Component {
  state = {
    totalCount: {
      deviceCount: 0,
      gatewayCount: 0,
    },
    onlineCount: {
      deviceOnline: 0,
      gatewayOnline: 0,
    },
    offlineCount: {
      totalOfflineDevices: 0,
      totalOfflineGateway: 0,
    },
    themeState:
      document.documentElement.getAttribute("data-theme") === "light"
        ? "light"
        : "dark",
  };

  componentDidMount() {
    this.handleThemeChange();

    // Update theme color when the theme changes
    window.addEventListener("storage", this.handleThemeChange);
  }

  componentWillUnmount() {
    // Clean up the event listener
    window.removeEventListener("storage", this.handleThemeChange);
  }

  handleThemeChange = () => {
    this.setState({
      themeState:
        document.documentElement.getAttribute("data-theme") === "light"
          ? "light"
          : "dark",
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { deviceConectedStatus, devicesStatusData } = this.props;
    if (prevProps.deviceConectedStatus !== this.props.deviceConectedStatus) {
      const {
        deviceCount,
        gatewayCount,
        totalOfflineDevices,
        totalOfflineGateway,
      } = deviceConectedStatus;
      this.setState({
        totalCount: {
          deviceCount: Number(deviceCount),
          gatewayCount: Number(gatewayCount),
        },
        offlineCount: {
          totalOfflineDevices: Number(totalOfflineDevices),
          totalOfflineGateway: Number(totalOfflineGateway),
        },

        onlineCount: {
          deviceOnline: Number(deviceCount) - Number(totalOfflineDevices),
          gatewayOnline: Number(gatewayCount) - Number(totalOfflineGateway),
        },
      });
    }
    if (Object.keys(this.props.devicesStatusData).length !== 0) {
      if (prevProps.devicesStatusData !== this.props.devicesStatusData) {
        const { totalOfflineDevices, totalOfflineGateways } = devicesStatusData;

        this.setState({
          onlineCount: {
            deviceOnline:
              this.state.totalCount.deviceCount - Number(totalOfflineDevices),
            gatewayOnline:
              this.state.totalCount.gatewayCount - Number(totalOfflineGateways),
          },
        });
      }
    }
    if (prevProps.deviceConectedStatus !== this.props.deviceConectedStatus) {
      this.setState({
        totalCount: {
          deviceCount: Number(this.props.deviceConectedStatus.deviceCount),
          gatewayCount: Number(this.props.deviceConectedStatus.gatewayCount),
        },
      });
    }
  }
  render() {
    const { isExpanded } = this.props;

    const svgClass = {
      width: "72%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      marginLeft: "-35px",
    };

    const RenderSVG = ({ color, value, status }) => {
      return (
        <div style={{ width: "75px" }}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 159 118"
          >
            <path
              class="st0"
              d="M39.2,86.5c-0.9-3.5-1.4-7.2-1.4-10.9c0-23.3,18.9-42.3,42.3-42.3s42.3,18.9,42.3,42.3c0,3.7-0.5,7.4-1.4,10.8
              l22.9,7.1c1.6-5.7,2.5-11.7,2.5-17.9c0-36.6-29.7-66.2-66.2-66.2S13.8,38.9,13.8,75.5c0,6.6,1,13.1,2.8,19.1L39.2,86.5z"
              fill={color}
            />
          </svg>
          <div style={{ textAlign: "center", marginTop: "-34px" }}>
            <h2
              style={{
                fontSize: "1.5rem",
                color: status === "Inactive" ? "rgb(224, 47, 68)" : color,
              }}
            >
              {value || 0}
            </h2>
            <p>{status}</p>
          </div>
        </div>
      );
    };

    return (
      <Card
        className={`recent-alarms   ${isExpanded ? "expanded" : ""}`}
        style={{
          height: "145px", // Adjust these values as needed
          transition: "height 0.3s ease-in-out",
          width: "100%",
          marginBottom: "0.35rem !important",
          border: "1px solid var(--dropdown-border-bg)",
        }}
      >
        <CardHeader
          className="recent-alarms__header commonSpacing"
          style={{ position: "relative" }}
        >
          <CardTitle>Connected Devices</CardTitle>
          <div
            style={{
              display: "flex",
              width: "40%",
              height: "40px",
              justifyContent: "flex-end",
            }}
          />
        </CardHeader>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#ccc",
            color: "transparent",
          }}
        >
          <span>title</span>
        </div>
        <CardBody
          style={{
            marginTop: "-5px",
          }}
          className={
            "d-flex flex-row  justify-content-center align-items-center "
          }
        >
          <div className="w-50 d-flex justify-content-center align-items-center p-2">
            <div className="text-start ">
              <img
                alt="gateway"
                src={
                  this.state.themeState === "light"
                    ? "img/custom-device-1.png"
                    : "img/custom-device-1-white.png"
                }
                className={"w-30 h-30"}
                style={{
                  marginLeft: "40px",
                }}
              />
            </div>
            <div style={svgClass} className="mt-1 ">
              <RenderSVG
                color="#72bf69"
                value={
                  this.props.deviceStatusResult &&
                  this.props.deviceStatusResult.gateways.activeGateways
                }
                status="Active"
              />
              <RenderSVG
                color="#23252a"
                value={
                  this.props.deviceStatusResult &&
                  this.props.deviceStatusResult.gateways.inActiveGateways
                }
                status="Inactive"
              />
            </div>
          </div>

          <div className="w-60 d-flex justify-content-center align-items-center p-2">
            <div
              style={{
                marginRight: "-40px",
              }}
              className="text-center"
            >
              <img
                src={
                  this.state.themeState === "light"
                    ? "img/custom-device-2.png"
                    : "img/custom-device-2-white.png"
                }
                className="h-30"
                style={{ width: "20%" }}
                alt="node"
              />
            </div>

            <div className="mt-1" style={svgClass}>
              <RenderSVG
                color="#72bf69"
                value={
                  this.props.deviceStatusResult &&
                  this.props.deviceStatusResult.devices.activeDevices
                }
                status="Active"
              />
              <RenderSVG
                color="#23252a"
                value={
                  this.props.deviceStatusResult &&
                  this.props.deviceStatusResult.devices.inActiveDevices
                }
                status="Inactive"
              />
            </div>
          </div>
          <div
            style={{
              height: "100%",
              width: "30px",
            }}
          />
        </CardBody>
      </Card>
    );
  }
}

export default memo(Status);
