import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import { withNamespaces, Trans } from "react-i18next";
import ContentWrapper from "../Layout/ContentWrapper";
import { Container, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import CardTool from "../Common/CardTool";
import ProfileTable from "./profileTable";

class AlertProfile extends Component {
  state = {};
  componentDidMount() {
    const { getAlertProfileList } = this.props;
    getAlertProfileList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevProps.success !== this.props.success && this.props.success) {
      this.props.getAlertProfileList();
      if (this.props.success === "Alert Profile deleted successfully.") {
        this.props.setModal({
          showModal: false,
        });
      }
    }
    if (
      prevProps.temperatureUnit !== this.props.temperatureUnit &&
      this.props.temperatureUnit
    ) {
      this.props.getAlertProfileList();
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      //   this.props.getAlertProfileList(1);
    }
    if (prevProps.saved !== this.props.saved && this.props.saved) {
      this.props.getAlertProfileList();
    }
  }

  showModal = (modalName, modalData) => {
    const { setModal } = this.props;
    setModal({
      showModal: true,
      modalName: modalName,
      modalData: modalData,
    });
  };

  editProfile = (data) => {
    this.showModal("alertProfiles", { data: data });
  };

  deleteProfile = (id) => {
    this.showModal("deleteUser", { id: id, component: "alertProfile" });
    // this.props.deleteProfile(id);
  };

  changePage = (page) => {
    // this.props.getAlertProfileList(page);
  };

  render() {
    const { alertProfileList, meta, loading, temperatureUnit } = this.props;
    const role = localStorage.getItem("role");

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Alert Profile
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          <div className="d-flex p-3 justify-content-end">
            {this.props.userRole === "super_user" && (
              <button
                className="mr-2 float-right btn-labeled btn btn-info"
                onClick={() => {
                  this.showModal("alertProfiles");
                }}
              >
                <span className="btn-label">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </span>
                Add
              </button>
            )}
          </div>
          <Card className="card-default">
            <CardHeader
              tag="strong"
              className="d-flex align-items-center justify-content-between"
            >
              <CardTitle>Alert Profiles</CardTitle>
              <CardTool
              // refresh
              // onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <div
              style={{
                height: "1px",
                width: "100%",
                background: "#ccc",
              }}
            />
            <CardBody>
              {alertProfileList && alertProfileList.length > 0 && (
                <ProfileTable
                  //   pagination={meta.pagination}
                  profileList={alertProfileList}
                  editProfile={this.editProfile}
                  deleteProfile={this.deleteProfile}
                  changePage={(page) => this.changePage(page)}
                  getAlertProfileList={() => this.props.getAlertProfileList()}
                  userRole={this.props.userRole}
                  temperatureUnit={temperatureUnit}
                ></ProfileTable>
              )}
              {loading ? <h3 className="text-center">Loading...</h3> : ""}
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.alertProfiles.loading,
    alertProfileList: state.alertProfiles.alertProfileList,
    meta: state.alertProfiles.meta,
    error: state.alertProfiles.error,
    success: state.alertProfiles.success,
    reload: state.alertProfiles.reload,
    userRole:
      !!state.dashboard &&
      !!state.dashboard.dashboard &&
      !!state.dashboard.dashboard.userInfo &&
      state.dashboard.dashboard.userInfo.role,
    temperatureUnit: state.auth.temperatureUnit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAlertProfileList: (data) => dispatch(actions.getAlertProfileList()),
    setModal: (data) => dispatch(actions.setModal(data)),
    deleteProfile: (data) => dispatch(actions.deleteProfile(data)),

    // getUserIds: (data) => dispatch(actions.getUserIds(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(AlertProfile));
