import React, { Component } from "react";
import { convertDateFormat } from "../../utils/helperFunctions";

import { MDBBtn, MDBDataTable } from "mdbreact";
import { Row, Col } from "reactstrap";
import Pagination from "../Common/Pagination";
const columns = [
  {
    label: "Report Name",
    field: "name",
    sort: "asc",
    width: 250,
  },
  {
    label: "Temperature",
    field: "temp",
    sort: "asc",
    width: 150,
  },
  {
    label: "Humidity",
    field: "humidity",
    sort: "asc",
    width: 150,
  },
  {
    label: "Carbon",
    field: "carbon",
    sort: "asc",
    width: 150,
  },
  {
    label: "Pressure",
    field: "pressure",
    sort: "asc",
    width: 150,
  },
  {
    label: "Report",
    field: "enable",
    sort: "asc",
    width: 150,
  },
  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 250,
  },
  {
    label: "Action",
    field: "action",
    width: 80,
  },
];

class ReportTable extends Component {
  state = {
    loadTable: false,
    pagination: {},
  };

  componentDidMount() {
    const { reports } = this.props;
    if (reports && reports.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.reports !== this.props.reports &&
      this.props.reports &&
      this.props.reports.length
    ) {
      this.showTable(true);
    }
  }

  showTable = (loadTable) => {
    if (loadTable) {
      const { reports, editReport, deleteReport, pagination } = this.props;
      const component = this;
      const rows = reports.map((report, index) => {
        let reportRow = { ...report };
        reportRow.temp = reportRow.temp === 1 ? "Enabled" : "Disabled";
        reportRow.humidity = reportRow.humidity === 1 ? "Enabled" : "Disabled";
        reportRow.carbon = reportRow.carbon === 1 ? "Enabled" : "Disabled";
        reportRow.enable = reportRow.enable === 1 ? "Enabled" : "Disabled";
        reportRow.pressure = reportRow.pressure === 1 ? "Enabled" : "Disabled";
        reportRow.createdAt = convertDateFormat(
          reportRow.createdAt,
          "shortDateTime"
        );
        reportRow.action = (
          <>
            <MDBBtn
              onClick={deleteReport.bind(component, reportRow.id)}
              tag="a"
              rounded
              size="sm"
              className="px-2 transparentBtn"
            >
              <i className="fas fa-trash mt-0"></i>
            </MDBBtn>
            <MDBBtn
              onClick={editReport.bind(this, reportRow)}
              tag="a"
              rounded
              size="sm"
              className="px-2 transparentBtn"
            >
              <i className=" fas fa-pencil-alt mt-0"></i>
            </MDBBtn>
          </>
        );
        return reportRow;
      });
      this.setState({
        loadTable,
        reportList: {
          columns: columns,
          rows: rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { changePage } = this.props;
    const { loadTable, reportList, pagination } = this.state;

    return (
      <React.Fragment>
        {loadTable ? (
          <div>
            <MDBDataTable
              className="hideLastColSort recentAlarmTable border-0"
              bordered
              data={reportList}
              paging={false}
              scrollX
            />
            <Row className="mt-3 mb-3">
              <Col lg={6}>
                <label className="ml-3">
                  Showing {pagination.currentPage * 10 - 9} to{" "}
                  {pagination.currentPage * 10} of all results
                </label>
              </Col>
              <Col lg={6} className="text-right">
                <Pagination
                  pagination={pagination}
                  changePage={(page) => changePage(page)}
                ></Pagination>
              </Col>
            </Row>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ReportTable;
