import React, { Component } from "react";
import { appInfo } from "../../config/config";
import "./index.scss";
class AuthBase extends Component {
  render() {
    const savedTheme = localStorage.getItem("data-theme");
    document.documentElement.setAttribute("data-theme", savedTheme);

    return (
      <div className="wrapper d-flex align-items-center">
        <div className="block-center mt-4 wd-xxl">
          <div className="overrideCard">
            <div
              className="card card-flat p-3"
              style={{
                boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.05)",
                borderRadius: "10px",
              }}
            >
              <div className="text-center">
                {savedTheme !== "light" ? (
                  <a href="/">
                    <img
                      width="100%"
                      className="block-center rounded"
                      src="/img/LARIAN-TECH.png"
                      alt="Logo"
                    />
                  </a>
                ) : (
                  <a href="/">
                    <img
                      width="100%"
                      className="block-center rounded"
                      src="img/LARIAN-TECH-2.png"
                      alt="App Logo"
                    />
                  </a>
                )}
              </div>
              <div className="card-body">{this.props.children}</div>
            </div>
          </div>
          <div className="p-3 text-center">
            <span className="mr-2">&copy;</span>
            <span>{appInfo.year}</span>
            <span className="mx-2">-</span>
            <span>{appInfo.name}</span>
            <br />
            <span>{appInfo.desc}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthBase;
