import React from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import Content from "./Content";
import "./index.styles.scss";

class LocationDevices extends React.Component {
  render() {
    const { isExpanded } = this.props;

    return (
      <Card
        style={{
          height: isExpanded ? "246px" : "270px",
          marginTop: isExpanded ? "25px" : "0px",
        }}
        className="card-default location-devices  mb-md-0 mb-2"
      >
        <CardHeader className="d-flex align-items-center justify-content-between card-header-border">
          <CardTitle>Locations &amp; Devices</CardTitle>
        </CardHeader>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#ccc",
          }}
        />

        <CardBody
          style={{
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Content {...this.props} />
        </CardBody>
      </Card>
    );
  }
}

export default LocationDevices;
