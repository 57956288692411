import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import { showToast } from "../../helpers/toast.js";
import { withNamespaces, Trans } from "react-i18next";
import ContentWrapper from "../Layout/ContentWrapper.js";
import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Input,
  Button,
} from "reactstrap";
import { selectStyle } from "../../config/selectStyle.js";
import Select from "react-select";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import ReactDatePicker from "react-datepicker";
import CardTool from "../Common/CardTool.js";
import AuditTrailTable from "./AuditTrailTable.js";
import * as moment from "moment";

const now = new Date();
class AuditTrail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: null,
      startDate: new Date(),
      endDate: new Date(),
      event: {
        id: "",
        name: "Select Event...",
      },
      accountId: null,
      action: {
        id: "",
        name: "Select Action...",
      },
      nextToken: null,
      downloadPDFStatus: "",
      eventList: [
        {
          id: "",
          name: "Select event...",
        },
        {
          id: "USER",
          name: "User",
        },
        {
          id: "REPORT",
          name: "Report",
        },
        {
          id: "DEVICE",
          name: "Device",
        },
        {
          id: "GATEWAY",
          name: "Gateway",
        },
        {
          id: "ALARM",
          name: "Alarm",
        },
        {
          id: "ACCOUNT",
          name: "Account",
        },
        {
          id: "LIGHT_PROFILE",
          name: "Light profile",
        },
        {
          id: "LOCATION",
          name: "Location",
        },
        {
          id: "MAP",
          name: "Map",
        },
      ],
      actionList: [
        { id: "", name: "Select Action..." },
        { id: "LOGIN", name: "Login" },
        { id: "LOGOUT", name: "Logout" },
        { id: "REGISTER", name: "Register" },
        { id: "FORGOT_PASSWORD", name: "Forgot password" },
        { id: "ADD", name: "Add" },
        { id: "UPDATE", name: "Update" },
        { id: "DELETE", name: "Delete" },
        { id: "ADD_DEVICE_TOKEN", name: "Add device token" },
        { id: "REMOVE_DEVICE_TOKEN", name: "Remove device token" },
        { id: "GET", name: "Get" },
        { id: "DOWNLOAD_PDF", name: "Download PDF" },
        { id: "DOWNLOAD_CSV", name: "Download CSV" },
        { id: "ASSIGN", name: "Assign sub user" },
        { id: "UNASSIGN", name: "Unassign sub user" },
        { id: "UNASSIGN_LOCATION", name: "Unassign location" },
        { id: "SET_ALARM", name: "Set alarm" },
        { id: "UPDATE_ALARM", name: "Update alarm" },
        { id: "DELETE_ALARM", name: "Delete alarm" },
        { id: "SET_ALARM_PROFILE", name: "Set alarm profile" },
        { id: "UPDATE_ALARM_PROFILE", name: " Update alarm profile" },
        { id: "DELETE_ALARM_PROFILE", name: "Delete alarm profile" },
        { id: "ENABLE_ALARM", name: "Enable alarm" },
        { id: "UPDATE_FREQUENCY", name: "Update frequency" },
        { id: "ADD_SMS_LIMIT", name: "Set sms limit" },
        { id: "UPDATE_ACCOUNT_SETTINGS", name: "Update account settings" },
        { id: "UPDATE_VOICE_CALL", name: "Update voice call" },
        { id: "UPDATE_OFFLINE_SETTING", name: "Update offline settings" },
        { id: "SET_TEMPLATE_TO_USER", name: "Set message template to user" },
      ],
    };
  }

  componentDidMount() {
    const { getAuditTrail, getAccountList } = this.props;
    this.props.getAuditTrail({
      limit: this.state.limit ? this.state.limit : null,
      startDate: this.state.startDate
        ? moment(this.state.startDate).format("YYYY-MM-DD")
        : null,
      endDate: this.state.endDate
        ? moment(this.state.endDate).format("YYYY-MM-DD")
        : null,
      event: this.state.event.id ? this.state.event.id : null,
      accountId: this.state.accountId ? this.state.accountId : null,
      action: this.state.action.id ? this.state.action.id : null,
      nextToken: null,
    });
    getAccountList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");
      this.changePage(1);
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }
    if (prevProps.reload !== this.props.reload && this.props.reload) {
      this.props.setModal({ showModal: false, modalName: "", modalData: {} });
      this.props.getAuditTrail({
        page: 1,
        pageSize: 10,
      });
    }
    if (this.props.task && this.props.task.taskId) {
      if (
        !prevProps.task ||
        !prevProps.task.taskId ||
        prevProps.task.taskId !== this.props.task.taskId
      ) {
        this.handlePolling(this.props.task.taskId);
      }
    }
  }

  handlePolling = async (taskId) => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    try {
      const headers = getCommonHeaders();
      let url = `${apiPath.baseUrl}${apiPath.auditTrail.task}`;
      url = url.replace("{id}", taskId);
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const result = await response.json();
      if (result.data && result.data.status !== "completed") {
        let status = result.data.status;
        if (result.data.progress) {
          status += " " + result.data.progress + "%";
        }

        this.setState({
          ...this.state,
          downloadPDFStatus: status,
        });
        await delay(5000);
        this.handlePolling(taskId);
      } else if (result.data && result.data.status === "completed") {
        this.setState({
          ...this.state,
          downloadPDFStatus: "",
        });
        window.open(result.data.resultUrl, "_blank");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  changePage = (nextToken = null) => {
    if (
      new Date(this.state.endDate).getTime() <
      new Date(this.state.startDate).getTime()
    ) {
      showToast("Start Date should not be greater than End Date", "error");
    } else {
      this.props.getAuditTrail({
        limit: this.state.limit ? this.state.limit : null,
        startDate: this.state.startDate ? this.state.startDate : null,
        endDate: this.state.endDate ? this.state.endDate : null,
        event: this.state.event.id ? this.state.event.id : null,
        accountId: this.state.accountId ? this.state.accountId : null,
        action: this.state.action.id ? this.state.action.id : null,
        nextToken: nextToken ? nextToken : null,
      });
    }
  };

  downloadPDF = () => {
    this.props.pdfExportAuditTrail({
      limit: this.state.limit ? this.state.limit : null,
      startDate: this.state.startDate ? this.state.startDate : null,
      endDate: this.state.endDate ? this.state.endDate : null,
      event: this.state.event.id ? this.state.event.id : null,
      accountId: this.state.accountId ? this.state.accountId : null,
      action: this.state.action.id ? this.state.action.id : null,
    });
  };

  handleChangeEvent = (event) => {
    this.setState({ event });
  };

  isEventSelected = (option) => {
    return this.state.event.id === option.id ? true : false;
  };

  handleChangeAction = (action) => {
    this.setState({ action });
  };

  isActionSelected = (option) => {
    return this.state.action.id === option.id ? true : false;
  };

  render() {
    const { data, loading, task } = this.props;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Audit Trail
            <small>
              <Trans i18nKey="dashboard.WELCOME"></Trans>
            </small>
          </div>
        </div>

        <Container fluid>
          <Card className="card-default">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <CardTitle>Audit Trail</CardTitle>
              <CardTool
                refresh
                onRefresh={(_, done) => setTimeout(done, 2000)}
              />
            </CardHeader>
            <div
              style={{
                height: "1px",
                width: "100%",
                background: "#ccc",
              }}
            />
            <div className="form-group m-2">
              <div className="row">
                {/*}
                    <div className="col-3 mt-2">
                      
                    <select style={{
                        backgroundColor: "#272934", 
                        color: "#fff",
                        borderBottom: "1px solid black",
                        width: "100%",
                        padding: "9px",
                        opacity: "0.7"}}
                        onChange={(data) => this.setState({ accountId: data.target.value })}
                        >
                        <option value="0" selected={!this.state.accountId ? "selected" : "" }>Select...</option>
                        {account.map((el) => (
                          <option value={el.id} selected={(el.id === this.state.accountId) ? "selected" : "" }>{el.companyName}</option>
                        )
                        )}
                      </select>
                    </div>  
                    {*/}
                <div className="col-3 mt-2">
                  <Select
                    styles={selectStyle}
                    name="eventId"
                    value={this.state.event}
                    onChange={this.handleChangeEvent}
                    options={this.state.eventList}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => {
                      this.isEventSelected(option);
                    }}
                  />
                  {/* <select
                    style={{
                      backgroundColor: "#272934",
                      color: "#fff",
                      borderBottom: "1px solid black",
                      width: "100%",
                      padding: "9px",
                      opacity: "0.7",
                    }}
                    onChange={(data) =>
                      this.setState({ event: data.target.value })
                    }
                  >
                    <option value="">Select event...</option>
                    <option value="USER">User</option>
                    <option value="REPORT">Report</option>
                    <option value="DEVICE">Device</option>
                    <option value="GATEWAY">Gateway</option>
                    <option value="GATEWAY">Alarm</option>
                    <option value="ACCOUNT">Account</option>
                    <option value="LIGHT_PROFILE">Light profile</option>
                    <option value="LOCATION">Location</option>
                    <option value="LOCATION">Map</option>
                  </select> */}
                </div>
                <div className="col-3 mt-2">
                  <Select
                    styles={selectStyle}
                    name="actionId"
                    value={this.state.action}
                    onChange={this.handleChangeAction}
                    options={this.state.actionList}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => {
                      this.isActionSelected(option);
                    }}
                  />
                  {/* <select
                    style={{
                      backgroundColor: "#272934",
                      color: "#fff",
                      borderBottom: "1px solid black",
                      width: "100%",
                      padding: "9px",
                      opacity: "0.7",
                    }}
                    onChange={(data) =>
                      this.setState({ action: data.target.value })
                    }
                  >
                    <option value="">Select action...</option>
                    <option value="LOGIN">Login</option>
                    <option value="LOGOUT">Logout</option>
                    <option value="REGISTER">Register</option>
                    <option value="FORGOT_PASSWORD">Forgot password</option>
                    <option value="ADD">Add</option>
                    <option value="UPDATE">Update</option>
                    <option value="DELETE">Delete</option>
                    <option value="ADD_DEVICE_TOKEN">Add device token</option>
                    <option value="REMOVE_DEVICE_TOKEN">
                      Remove device token
                    </option>
                    <option value="GET">Get</option>
                    <option value="DOWNLOAD_PDF">Download PDF</option>
                    <option value="DOWNLOAD_CSV">Download CSV</option>
                    <option value="ASSIGN">Assign sub user</option>
                    <option value="UNASSIGN">Unassign sub user</option>
                    <option value="UNASSIGN_LOCATION">Unassign location</option>
                    <option value="SET_ALARM">Set alarm</option>
                    <option value="UPDATE_ALARM">Update alarm</option>
                    <option value="DELETE_ALARM">Delete alarm</option>
                    <option value="SET_ALARM_PROFILE">Set alarm profile</option>
                    <option value="UPDATE_ALARM_PROFILE">
                      Update alarm profile
                    </option>
                    <option value="DELETE_ALARM_PROFILE">
                      Delete alarm profile
                    </option>
                    <option value="ENABLE_ALARM">Enable alarm</option>
                    <option value="UPDATE_FREQUENCY">Update frequency</option>
                    <option value="ADD_SMS_LIMIT">Set sms limit</option>
                    <option value="UPDATE_ACCOUNT_SETTINGS">
                      Update account settings
                    </option>
                    <option value="UPDATE_VOICE_CALL">Update voice call</option>
                    <option value="UPDATE_OFFLINE_SETTING">
                      Update offline settings
                    </option>
                    <option value="SET_TEMPLATE_TO_USER">
                      Set message template to user
                    </option>
                  </select> */}
                </div>
                <div className="col-6 mt-2 d-flex justify-content-between">
                  <div style={{ width: "50%" }} className="styleDatePicker">
                    Start Date:{" "}
                    <ReactDatePicker
                      selected={this.state.startDate}
                      onChange={(date) => this.setState({ startDate: date })}
                      className="form-control form-control-sm ml-2"
                      style={{ width: "100%" }}
                      //showTimeSelect
                      //showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Date"
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                    />
                  </div>
                  <div
                    className="ml-3 styleDatePicker"
                    style={{ width: "50%" }}
                  >
                    End Date:{" "}
                    <ReactDatePicker
                      selected={this.state.endDate}
                      onChange={(date) => this.setState({ endDate: date })}
                      style={{ width: "100%" }}
                      className="form-control form-control-sm w-100 ml-2"
                      //showTimeSelect
                      //showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Date"
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                    />
                  </div>
                </div>
                <div className="col-3 mt-2">
                  <Button
                    onClick={() => {
                      this.changePage();
                    }}
                    color="info"
                    type="button"
                  >
                    Search
                  </Button>
                  <span className="ml-2">
                    <Button
                      onClick={() => {
                        this.setState({
                          startDate: null,
                          endDate: null,
                          event: {
                            id: "",
                            name: "Select Event...",
                          },
                          action: {
                            id: "",
                            name: "Select Action...",
                          },
                          accountId: null,
                        });
                      }}
                      color="info"
                      type="button"
                    >
                      Reset
                    </Button>
                  </span>
                  {!this.state.downloadPDFStatus ||
                  this.state.downloadPDFStatus === "completed" ? (
                    <span className="ml-2">
                      <Button
                        onClick={() => {
                          this.downloadPDF();
                        }}
                        color="info"
                        type="button"
                      >
                        Download PDF
                      </Button>
                    </span>
                  ) : (
                    <>
                      <span className={"fas fa-spinner fa-spin ml-2"}></span>{" "}
                      {this.state.downloadPDFStatus}
                    </>
                  )}
                </div>
              </div>
            </div>
            <CardBody>
              {data && data.length > 0 && (
                <AuditTrailTable
                  data={data}
                  editMessageTemplate={this.editMessageTemplate}
                  deleteMessageTemplate={this.deleteMessageTemplate}
                  changePage={(page) => this.changePage(page)}
                ></AuditTrailTable>
              )}
              {!!loading && <h3 className="text-center">Loading...</h3>}
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auditTrail.loading,
    data: state.auditTrail.data,
    meta: state.auditTrail.meta,
    success: state.auditTrail.success,
    account: state.account.data,
    task: state.auditTrail.task,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAuditTrail: (data) => dispatch(actions.getAuditTrail(data)),
    pdfExportAuditTrail: (data) => dispatch(actions.pdfExportAuditTrail(data)),
    getAccountList: (data) => dispatch(actions.getAccountList(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(AuditTrail));
