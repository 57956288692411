import * as actionTypes from "./actionTypes";
import axios from "axios";
import { apiPath } from "../../config/config";
import { getCommonHeaders } from "../../utils/utilities";
import { unauthorized } from "./auth";
import { showToast } from "../../helpers/toast";
// import { dispatch } from "d3-dispatch";

export const getAuditTrailStart = () => {
  return {
    type: actionTypes.GET_AUDIT_TRAIL_START,
  };
};

export const getAuditTrailSuccess = (data) => {
  return {
    type: actionTypes.GET_AUDIT_TRAIL_SUCCESS,
    data: data,
  };
};

export const getAuditTrailFail = (error) => {
  return {
    type: actionTypes.GET_AUDIT_TRAIL_FAIL,
    error: error,
  };
};


export const getAuditTrail = (params = {
  limit: null,
  startDate: null,
  endDate: null,
  event: null,
  accountId: null,
  action: null,
  nextToken: null
}) => {
  return (dispatch) => {
    dispatch(getAuditTrailStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.auditTrail.list}?limit=${params.limit}`;

    if (params.startDate) {
      url += `&startDate=${params.startDate}`;
    }
    if (params.endDate) {
      url += `&endDate=${params.endDate}`;
    }
    if (params.event) {
      url += `&event=${params.event}`;
    }
    if (params.accountId) {
      url += `&accountId=${params.accountId}`;
    }
    if (params.action) {
      url += `&action=${params.action}`;
    }
    if (params.nextToken) {
      url += `&nextToken=${params.nextToken}`;
    }

    axios
      .get(url, { headers })
      .then((response) => {
        dispatch(getAuditTrailSuccess(response));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(getAuditTrailFail(error.response.data.errors));
        }
      });
  };
};


export const pdfExportAuditTrailStart = () => {
  return {
    type: actionTypes.PDF_EXPORT_TRAIL_START,
  };
};

export const pdfExportAuditTrailSuccess = (data) => {
  return {
    type: actionTypes.PDF_EXPORT_TRAIL_SUCCESS,
    task: data,
  };
};

export const pdfExportAuditTrailFail = (error) => {
  return {
    type: actionTypes.PDF_EXPORT_TRAIL_FAIL,
    error: error,
  };
};

export const pdfExportAuditTrail = (params = {
  limit: null,
  startDate: null,
  endDate: null,
  event: null,
  accountId: null,
  action: null
}) => {
  return (dispatch) => {
    dispatch(pdfExportAuditTrailStart());
    const headers = getCommonHeaders();
    let url = `${apiPath.baseUrl}${apiPath.auditTrail.pdf}`;

    axios
      .post(url, {
        startDate: params.startDate, 
        endDate: params.endDate, 
        action: params.action, 
        account: params.accountId, 
        event: params.event,
        type: "log_data"
      }, { headers })
      .then((response) => {
        dispatch(pdfExportAuditTrailSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(unauthorized());
        } else {
          dispatch(pdfExportAuditTrailFail(error.response.data.errors));
        }
      });
  };
};