import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import { showToast } from "../../helpers/toast.js";
import * as actions from "../../stores/actions/index";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";

class AddEditUser extends Component {
  countyCodeList = ["+44", "+91", "+1"];
  state = {
    mode: "Add",
    formUser: {
      userName: "",
      linkToDoorLockUser: "",
      userEmail: "",
      userCountryCode: "+44",
      userPhone: "",
      userRoles: "",
    },
    id: "",
    dropdownOpen: false,
    userIds: [],
    allUserRoles: [
      { id: "user", name: "User" },
      { id: "super_user", name: "Admin" },
    ],
  };

  toggleDropDown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  addUserFn = (payload) => {
    const { addUser } = this.props;
    addUser(payload);
  };

  editUserFn = (payload, id) => {
    const { editUser } = this.props;
    editUser({ payload, id });
  };

  componentDidMount() {
    const { modalData, getUserIds } = this.props;
    getUserIds();
    if (modalData && modalData.user) {
      const { user, userIds } = modalData;

      this.setState({
        mode: "Edit",
        formUser: {
          userName: user.name,
          userEmail: user.email,
          userCountryCode: user.countryCode,
          userPhone: `${user.countryCode}${user.phoneNumber}`,
          linkToDoorLockUser: user.usr,
        },
        id: user.id,
        userIds,
      });
    } else if (modalData && modalData.userIds) {
      const { userIds } = modalData;
      this.setState({
        userIds,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error && this.props.error) {
      // showToast(this.props.error, "error");
    }
  }

  validateOnChange = (event) => {
    const state = validateOnChange(event, this.state);
    this.setState(state);
  };

  handlePhoneChange = (value, country, e, formattedValue) => {
    this.setState({
      formUser: {
        ...this.state.formUser,
        userPhone: `+${value}`,
        userCountryCode: "+" + country.dialCode.toString(),
      },
    });
  };

  handleUserRoles = (selectedRole) => {
    if (!!selectedRole && selectedRole.id) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          userRoles: selectedRole,
        },
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const state = onSubmit(e, this.state);
    this.setState(state);
    if (!state.hasError) {
      let phone = this.state.formUser.userPhone;
      let countryCode = this.state.formUser.userCountryCode;
      if (phone !== null && countryCode !== null) {
        phone = phone.slice(countryCode.length);
      } else {
        showToast("Please Enter Phone number", "error");
        return;
      }
      const params = {
        name: this.state.formUser.userName,
        email: this.state.formUser.userEmail,
        countryCode: countryCode,
        phoneNumber: phone,
        usr:
          this.state.formUser.linkToDoorLockUser === "N/A"
            ? ""
            : this.state.formUser.linkToDoorLockUser,
        ...(this.state.formUser.userRoles && this.state.formUser.userRoles.id
          ? { role: this.state.formUser.userRoles.id }
          : {}),
      };
      if (state.mode === "Add") {
        this.addUserFn(params);
      } else {
        this.editUserFn(params, state.id);
      }
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  countryCodeChange = (countryCode) => {
    this.setState({
      ...this.state,
      formUser: {
        ...this.state.formUser,
        userCountryCode: countryCode,
      },
    });
  };
  linkToDoorLockUserChange = (value) => {
    this.setState({
      formUser: {
        ...this.state.formUser,
        linkToDoorLockUser: value,
      },
    });
  };
  render() {
    const { toggleModal, dashboard, modalData } = this.props;
    const { mode } = this.state;
    const { userInfo } = dashboard;
    const { user } = modalData;
    let userRole = "";
    if (userInfo) {
      const { role } = userInfo;
      userRole = role;
    }

    const formattedUserId = [
      "N/A",
      ...(!!this.state.formUser.linkToDoorLockUser &&
      this.state.formUser.linkToDoorLockUser !== "N/A"
        ? [this.state.formUser.linkToDoorLockUser]
        : []),
      ...this.props.userIds,
    ];

    return (
      <React.Fragment>
        <form className="mb-3" name="formUser" onSubmit={this.onSubmit}>
          <ModalHeader tag="strong" toggle={toggleModal}>
            {mode} User
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="text-muted">User Name</label>
              <div className="input-group with-focus">
                <Input
                  type="text"
                  name="userName"
                  placeholder="Enter user name"
                  invalid={this.hasError("formUser", "userName", "required")}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={
                    !!this.state.formUser.userName.trim()
                      ? this.state.formUser.userName
                      : ""
                  }
                  minLength="2"
                />
                {this.hasError("formUser", "userName", "required") && (
                  <span className="invalid-feedback">Name is required</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="text-muted">User Email</label>
              <div className="input-group with-focus">
                <Input
                  type="email"
                  name="userEmail"
                  readOnly={
                    userRole === "super_admin"
                      ? false
                      : this.state.mode === "Add"
                      ? false
                      : true
                  }
                  placeholder="Enter user email"
                  invalid={
                    this.hasError("formUser", "userEmail", "required") ||
                    this.hasError("formLogin", "userEmail", "email")
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={this.state.formUser.userEmail || ""}
                  minLength="2"
                />
                {this.hasError("formUser", "userEmail", "required") && (
                  <span className="invalid-feedback">Email is required</span>
                )}
                {this.hasError("formLogin", "userEmail", "email") && (
                  <span className="invalid-feedback">Email must be valid</span>
                )}
              </div>
            </div>
            {mode === "Add" && localStorage.getItem("role") === "super_user" && (
              <div className="form-group">
                <label className="text-muted">Select Role</label>
                <Select
                  styles={selectStyle}
                  name="role"
                  value={this.state.formUser.userRoles}
                  onChange={this.handleUserRoles}
                  options={this.state.allUserRoles}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option}`}
                />
              </div>
            )}
            {/* {(localStorage.getItem('role') !== "super_user" || (localStorage.getItem('role') === "super_user" && ((user && user.role === "User") || (this.state.formUser.userRoles && this.state.formUser.userRoles.id === "user")))) && */}
            <div className="form-group">
              <label className="text-muted">Link Door</label>
              <div style={{ width: "100%" }}>
                <Select
                  styles={selectStyle}
                  value={formattedUserId.filter(
                    (option) =>
                      option === this.state.formUser.linkToDoorLockUser
                  )}
                  onChange={this.linkToDoorLockUserChange}
                  options={formattedUserId}
                  getOptionLabel={(option) => `${option}`}
                  getOptionValue={(option) => `${option}`}
                />
              </div>
            </div>
            {/* } */}
            <div className="form-group">
              <label className="text-muted">User Phone Number</label>
              <PhoneInput
                className="w-100"
                country="gb"
                countryCodeEditable={false}
                value={this.state.formUser.userPhone}
                onChange={this.handlePhoneChange}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" type="submit">
              {mode === "Add" ? "Add" : "Save"}
            </Button>
            <Button color="secondary" type="button" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard.dashboard,
    error: state.users.error,
    userIds: state.users.userIds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data) => dispatch(actions.addUser(data)),
    editUser: (data) => dispatch(actions.editUser(data)),
    getUserIds: (data) => dispatch(actions.getUserIds(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
