import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";
import { getTimeZones } from "@vvo/tzdb";
import "../Dashboard/LocationDevices/index.styles.scss";
import "../Dashboard/DeviceStatus/index.styles.scss";
import { showToast } from "../../helpers/toast";

class AddEditDevice extends Component {
  state = {
    mode: "Add",
    isDeviceIdEditable: false,
    password: "",
    editDeviceId: false,
    spillSensor: false,
    invert_water_signal: false,
    formDevice: {
      deviceName: "",
      deviceId: "",
      gatewayId: "",
      locationId: "",
      // maSensor: false,
      offlineAlarmEnable: true,
      equipmentStatus: true,
      offlineAlarmFrequency: {
        id: 0,
        title: 0,
      },
      ttl: {
        id: 60,
        title: 60,
      },
      // maSensorType: "pressure",
      // maSensorTypeValue: "bar",
      // conversionFactor: 0,
      // conversionFactorTwo: 0,
    },

    // sensorConfiguration: [
    //   {
    //     name: "Pressure",
    //     id: "pressure",
    //   },
    //   {
    //     name: "Level",
    //     id: "level",
    //   },
    //   {
    //     name: "Flow",
    //     id: "flow",
    //   },
    //   {
    //     name: "O2",
    //     id: "oxygen",
    //   },
    // ],
    // sensorValues: [
    //   { name: "pressure", values: ["bar", "psi"] },
    //   { name: "level", values: ["%"] },
    //   { name: "flow", values: ["l/min"] },
    //   { name: "oxygen", values: ["%", "ppm"] },
    // ],

    equipmentType: { id: "", name: "" },
    id: "",
    selectedGateway: {
      id: "",
      name: "",
    },
    selectedLocation: {
      id: "",
      name: "",
    },
    selectedTimezone: { id: "Etc/UTC", currentTimeFormat: "Etc/UTC" },
    equipmentTypes: [
      {
        id: "+4C",
        name: "+4C",
      },
      {
        id: "-20C",
        name: "-20C",
      },
      {
        id: "-30C",
        name: "-30C",
      },
      {
        id: "-80C",
        name: "-80C",
      },
      {
        id: "Incubator",
        name: "Incubator",
      },
      { id: "Cryofreezer", name: "Cryofreezer" },
    ],
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.formDevice.equipmentStatus !==
        this.state.formDevice.equipmentStatus &&
      !this.state.formDevice.equipmentStatus
    ) {
      this.setState({
        formDevice: {
          ...this.state.formDevice,
          offlineAlarmEnable: false,
        },
      });
    }
  }
  //   const { formDevice, mode } = this.state;
  //   const { modalData } = this.props;
  //   const { device } = modalData;

  //   // const filteredValue = this.state.sensorValues.filter(
  //   //   (val) => val.name === formDevice.maSensorType
  //   // );
  //   // const finalResult = filteredValue.length && filteredValue[0].values[0];

  //   if (
  //     finalResult &&
  //     formDevice.maSensorType !== "pressure" &&
  //     formDevice.maSensorTypeValue !== finalResult &&
  //     !formDevice.maSensorTypeValue
  //   ) {
  //     this.setState({
  //       formDevice: {
  //         ...formDevice,
  //         maSensorTypeValue: finalResult,
  //         conversionFactor: 0,
  //         conversionFactorTwo: 0,
  //       },
  //     });
  //   }
  //   if (
  //     prevState.formDevice.maSensorType !== this.state.formDevice.maSensorType
  //   ) {
  //     const maSensorTypeValueNew = this.state.sensorValues.filter(
  //       (val) => val.name === this.state.formDevice.maSensorType
  //     );
  //     if (device.maSensorType !== this.state.formDevice.maSensorType) {
  //       this.setState({
  //         formDevice: {
  //           ...this.state.formDevice,
  //           maSensorTypeValue: maSensorTypeValueNew[0].values[0],
  //           conversionFactor: "",
  //           conversionFactorTwo: "",
  //         },
  //       });
  //     } else {
  //       const filteredMaSensorValues = this.state.sensorValues.filter(
  //         (val) => val.name === this.state.formDevice.maSensorType
  //       );
  //       if (
  //         filteredMaSensorValues &&
  //         filteredMaSensorValues.length &&
  //         !filteredMaSensorValues[0].values.some(
  //           (val) => val === this.state.formDevice.maSensorTypeValue
  //         )
  //       ) {
  //         this.setState({
  //           formDevice: {
  //             ...this.state.formDevice,
  //             maSensorTypeValue: maSensorTypeValueNew[0].values[0],
  //             conversionFactor: "",
  //             conversionFactorTwo: "",
  //           },
  //         });
  //       }
  //     }
  //   }
  // }

  componentDidMount() {
    const { modalData } = this.props;
    if (modalData && modalData.device) {
      const { device } = modalData;
      const formattedTimezone = getTimeZones({ includeUtc: true }).filter(
        (val) => val.name === device.timezone
      );
      const offlineAlarmFrequencies = {
        30: "30 min",
        60: "1 hr",
        90: "1.5 hr",
        120: "2 hr",
      };
      let s = {
        mode: "Edit",

        formDevice: {
          deviceName: device.name,
          deviceId: device.deviceId,
          gatewayId: (device.gateway ? device.gateway.id : "") || null,
          locationId: (device.location ? device.location.id : "") || null,
          // maSensor: device.maSensor ? true : false,
          offlineAlarmEnable:
            device.offlineAlarmEnable === "Enabled" ? true : false,
          offlineAlarmFrequency: {
            id: device.offlineAlarmFrequency ? device.offlineAlarmFrequency : 0,
            title:
              device.offlineAlarmFrequency &&
              offlineAlarmFrequencies[device.offlineAlarmFrequency]
                ? offlineAlarmFrequencies[device.offlineAlarmFrequency]
                : 0,
          },
          ttl: {
            id: device.ttl ? device.ttl : 60,
            title: device.ttl ? device.ttl : 60,
          },
          equipmentStatus: device.equipmentStatus === "Inactive" ? false : true,
          // maSensorType: device.maSensorType || "pressure",
          // maSensorTypeValue: device.maSensorTypeValue || "bar",
          // conversionFactor: device.conversionFactor,
          // conversionFactorTwo: device.conversionFactorTwo,
        },
        equipmentType:
          {
            id: device.equipmentType,
            name: device.equipmentType,
          } || "",
        id: device.id,
        selectedGateway: {
          id: "",
          name: "",
        },
        selectedLocation: {
          id: "",
          name: "",
        },
        spillSensor: device.spillSensor === 1,
        invert_water_signal: device.invertWaterSignal === 1,
        selectedTimezone: {
          id: device.timezone,
          currentTimeFormat: !!device.timezone
            ? formattedTimezone.length > 0
              ? formattedTimezone[0].currentTimeFormat
              : "Etc/UTC"
            : "Etc/UTC",
        },
      };

      if (device.gateway) {
        s.selectedGateway = device.gateway;
      }
      if (device.location) {
        s.selectedLocation = device.location;
      }
      this.setState(s);
    }
  }

  addDeviceFn = (payload) => {
    const { addDevice } = this.props;
    addDevice(payload);
  };

  editDeviceFn = (payload, id) => {
    const { editDevice } = this.props;
    editDevice({ payload, id });
  };

  validateOnChange = (event) => {
    const state = validateOnChange(event, this.state);
    this.setState(state);
  };

  handleChangeGateway = (selectedGateway) => {
    this.setState({ selectedGateway });
  };

  handleChangeEquipment = (selectedEquipment) => {
    this.setState({ equipmentType: selectedEquipment });
  };

  isGatewaySelected = (option) => {
    return this.state.selectedGateway.id === option.id ? true : false;
  };

  isEquipmentTypeSelected = (option) => {
    return this.state.equipmentType.id === option.id ? true : false;
  };
  handleChangeLocation = (selectedLocation) => {
    this.setState({ selectedLocation });
  };

  isLocationSelected = (option) => {
    return this.state.selectedLocation.id === option.id ? true : false;
  };

  isTTLSelected = (option) => {
    return this.state.formDevice.ttl.id === option.id ? true : false;
  };

  onSubmit = (e) => {
    // if (this.state.selectedLocation.id) {
    const state = onSubmit(e, this.state);
    e.preventDefault();

    this.setState(state);
    if (!state.hasError) {
      let params = {
        deviceName: state.formDevice.deviceName,
        deviceId: state.formDevice.deviceId.toLowerCase(),
        gatewayId: null,
        locationId: null,
        // timezone: this.state.selectedTimezone.name,
        equipmentType: state.equipmentType.name,
        // maSensor: state.formDevice.maSensor,
        offlineAlarmEnable: state.formDevice.offlineAlarmEnable ? 1 : 0,
        offlineAlarmFrequency: state.formDevice.offlineAlarmFrequency.id,
        ttl: state.formDevice.ttl.id,
        equipmentStatus: state.formDevice.equipmentStatus ? 1 : 0,
        spillSensor: state.spillSensor,
        invertWaterSignal: state.invert_water_signal,
        // maSensorType: state.formDevice.maSensorType,
        // maSensorTypeValue: state.formDevice.maSensorTypeValue,
        // conversionFactor: state.formDevice.conversionFactor,
        // conversionFactorTwo: state.formDevice.conversionFactorTwo,
      };
      if (state.selectedGateway.id) {
        if (state.selectedGateway.id !== "unassign") {
          params.gatewayId = state.selectedGateway.id;
        } else {
          params.gatewayId = null;
        }
      }
      if (state.selectedLocation.id) {
        if (state.selectedLocation.id !== "unassign") {
          params.locationId = state.selectedLocation.id;
        } else {
          params.locationId = null;
        }
      }
      // if (
      //   state.formDevice.maSensor &&
      //   !state.formDevice.conversionFactor &&
      //   !state.formDevice.conversionFactorTwo
      // ) {
      //   showToast("Please Select atleast one Conversion Factor", "error");
      //   return false;
      // }

      if (state.mode === "Add") {
        this.addDeviceFn(params);
      } else {
        this.editDeviceFn(params, state.id);
      }
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  render() {
    const { toggleModal, gatewayList, locations } = this.props;
    const updatedGatewayList = gatewayList &&
      gatewayList.length && [{ id: "unassign", name: "N/A" }, ...gatewayList];
    const updatedLocations = locations &&
      locations.length && [{ id: "unassign", name: "N/A" }, ...locations];
    const { mode, formDevice } = this.state;

    // const timeZonesWithUtc = getTimeZones({ includeUtc: true }).map((val) => {
    //   return {
    //     id: val.name,
    //     name: val.name,
    //     abbreviation: val.abbreviation,
    //     currentTimeFormat: val.currentTimeFormat,
    //   };
    // });
    // const timeZones = getTimeZones().map((val) => {
    //   return {
    //     id: val.name,
    //     name: val.name,
    //     abbreviation: val.abbreviation,
    //     currentTimeFormat: val.currentTimeFormat,
    //   };
    // });
    // const filteredTimezones = timeZonesWithUtc.filter(
    //   (eachVal) => eachVal.abbreviation === "UTC"
    // );

    // const maSensorTypeValueDropdown = this.state.sensorValues.filter(
    //   (val) => val.name === formDevice.maSensorType
    // );

    return (
      <React.Fragment>
        <form className="mb-3" name="formDevice" onSubmit={this.onSubmit}>
          <ModalHeader tag="strong" toggle={toggleModal}>
            {mode} Device
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="text-muted">Device Name</label>
              <div className="input-group with-focus">
                <Input
                  type="text"
                  name="deviceName"
                  placeholder="Enter Device Name"
                  invalid={
                    this.hasError("formDevice", "deviceName", "required") ||
                    this.hasError("formDevice", "deviceName", "maxlen")
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required" , "maxlen"]'
                  value={
                    !!this.state.formDevice.deviceName.trim()
                      ? this.state.formDevice.deviceName
                      : ""
                  }
                  minLength="2"
                  data-param={30}
                />
                {this.hasError("formDevice", "deviceName", "required") && (
                  <span className="invalid-feedback">
                    Device name is required
                  </span>
                )}
                {this.hasError("formDevice", "deviceName", "maxlen") && (
                  <span className="invalid-feedback">
                    Device name shouldn't be longer than 30 characters
                  </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="text-muted">Device ID</label>
              <div
                className="input-group with-focus"
                style={{ position: "relative" }}
              >
                <Input
                  type="text"
                  name="deviceId"
                  placeholder="Enter Device ID"
                  invalid={this.hasError("formDevice", "deviceId", "required")}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={
                    !!this.state.formDevice.deviceId.trim()
                      ? this.state.formDevice.deviceId
                      : ""
                  }
                  minLength="2"
                  disabled={
                    this.state.mode === "Edit" && !this.state.editDeviceId
                  }
                />
                {this.state.mode === "Edit" && !this.state.editDeviceId && (
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState({
                        isDeviceIdEditable: true,
                      })
                    }
                  >
                    <i className=" fas fa-pencil-alt mt-0"></i>
                  </span>
                )}
                {this.hasError("formDevice", "deviceId", "required") && (
                  <span className="invalid-feedback">
                    Device Id is required
                  </span>
                )}
              </div>
            </div>
            {this.state.isDeviceIdEditable && !this.state.editDeviceId && (
              <div className="form-group">
                <label className="text-muted">
                  Enter Password to unlock DeviceID
                </label>
                <div className="input-group with-focus">
                  <Input
                    type="text"
                    name="deviceIDPassword"
                    placeholder="Enter Password "
                    onChange={(event) => {
                      const value = event.target.value;
                      this.setState({
                        password: value,
                      });
                    }}
                    data-param={30}
                  />
                  <Button
                    color="info"
                    style={{
                      borderTopLeftRadius: "0px !important",
                      borderBottomLeftRadius: "0px !important",
                    }}
                    onClick={() => {
                      if (this.state.password === "198104") {
                        this.setState({
                          editDeviceId: true,
                        });
                        showToast(
                          "You are now authorised to change Device ID",
                          "success"
                        );
                      } else {
                        showToast("Password didn't matched ", "error");
                      }
                    }}
                  >
                    Verify
                  </Button>
                </div>
              </div>
            )}
            <div className="form-group">
              <label className="text-muted">Equipment Type</label>
              <Select
                styles={selectStyle}
                name="equipmentType"
                value={this.state.equipmentType}
                onChange={this.handleChangeEquipment}
                options={this.state.equipmentTypes}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  this.isEquipmentTypeSelected(option);
                }}
              />
            </div>
            <div className="form-group">
              <label className="text-muted">Gateway (Optional)</label>
              <Select
                styles={selectStyle}
                name="gatewayId"
                value={this.state.selectedGateway}
                onChange={this.handleChangeGateway}
                options={updatedGatewayList}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  this.isGatewaySelected(option);
                }}
              />
            </div>
            <div className="form-group">
              <label className="text-muted">Location (Optional)</label>
              <Select
                styles={selectStyle}
                name="locationId"
                value={this.state.selectedLocation}
                onChange={this.handleChangeLocation}
                options={updatedLocations}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  this.isLocationSelected(option);
                }}
              />
            </div>
            <div className="form-group">
              <label className="text-muted">
                Offline alarm frequency (Optional)
              </label>
              <Select
                styles={selectStyle}
                name="offlineAlarmFrequency"
                value={this.state.formDevice.offlineAlarmFrequency}
                onChange={(data) => {
                  this.setState({
                    formDevice: {
                      ...this.state.formDevice,
                      offlineAlarmFrequency: data,
                    },
                  });
                }}
                options={[
                  {
                    id: 0,
                    title: "0 (Global settings will be considered)",
                  },
                  {
                    id: 30,
                    title: "30 min",
                  },
                  {
                    id: 60,
                    title: "1 hr",
                  },
                  {
                    id: 90,
                    title: "1.5 hr",
                  },
                  {
                    id: 120,
                    title: "2 hr",
                  },
                ]}
                getOptionLabel={(option) => `${option.title}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  return this.state.formDevice.offlineAlarmFrequency.id ===
                    option.id
                    ? true
                    : false;
                }}
              />
            </div>
            <div className="form-group">
              <label className="text-muted">
                First offline alert trigger after... (Optional)
              </label>
              <Select
                styles={selectStyle}
                name="ttl"
                value={this.state.formDevice.ttl}
                onChange={(data) => {
                  this.setState({
                    formDevice: {
                      ...this.state.formDevice,
                      ttl: data,
                    },
                  });
                }}
                options={[
                  {
                    id: 25,
                    title: 25,
                  },
                  {
                    id: 30,
                    title: 30,
                  },
                  {
                    id: 40,
                    title: 40,
                  },
                  {
                    id: 50,
                    title: 50,
                  },
                  {
                    id: 60,
                    title: 60,
                  },
                ]}
                getOptionLabel={(option) => `${option.title}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  this.isTTLSelected(option);
                }}
              />
            </div>
            <div className="row w-100 no-gutters">
              <div className="enableCheckbox d-flex col-lg-6 col-12 justify-content-start algin-items-center">
                <p className="mt-3">
                  <label className="checkbox-span">Spill Sensor</label>
                  <Input
                    type="checkbox"
                    id="spillSensor"
                    name="spillSensor"
                    checked={this.state.spillSensor}
                    onChange={() => {
                      this.setState({ spillSensor: !this.state.spillSensor });
                    }}
                  />
                  <label htmlFor="spillSensor" className="checkbox-label">
                    <span className="ui" />
                  </label>
                </p>
              </div>
              {this.state.spillSensor && (
                <div className="enableCheckbox d-flex col-lg-6 col-12 justify-content-start algin-items-center">
                  <p className="mt-3">
                    <label className="checkbox-span">
                      Invert Sensor Signal
                    </label>
                    <Input
                      type="checkbox"
                      id="invert_water_signal"
                      name="invert_water_signal"
                      checked={this.state.invert_water_signal}
                      onChange={() =>
                        this.setState({
                          invert_water_signal: !this.state.invert_water_signal,
                        })
                      }
                    />
                    <label
                      htmlFor="invert_water_signal"
                      className="checkbox-label"
                    >
                      <span className="ui" />
                    </label>
                  </p>
                </div>
              )}
            </div>

            {/* <div className="form-group">
              <label className="text-muted">Timezone</label>
              <Select
                styles={selectStyle}
                name="timezone"
                value={this.state.selectedTimezone}
                onChange={(timezone) => {
                  this.setState({
                    selectedTimezone: timezone,
                  });
                }}
                options={[...filteredTimezones, ...timeZones]}
                getOptionLabel={(option) => `${option.currentTimeFormat}`}
                getOptionValue={(option) => `${option}`}
              />
            </div> */}
            <div className="d-flex justify-content-between  algin-items-center">
              {/* <div className="enableCheckbox d-flex justify-content-start algin-items-center flex-column form-group pt-2">
                <h6 className="text-muted text-center">Analog Sensor</h6>
                <p className="pt-2">
                  <label className="text-muted">Disable</label>
                  <Input
                    type="checkbox"
                    id="maSensor"
                    name="maSensor"
                    checked={this.state.formDevice.maSensor}
                    onChange={this.validateOnChange}
                  />
                  <label htmlFor="maSensor" className="checkbox-label">
                    <span className="ui" />
                  </label>
                  <label className="text-muted">Enable</label>
                </p>
              </div> */}

              <div className="enableCheckbox d-flex justify-content-start algin-items-center flex-column form-group pt-2">
                <h6 className="text-muted text-center">
                  Device Offline Alerts
                </h6>
                <p className="pt-2">
                  <label className="text-muted">Disable</label>
                  <Input
                    type="checkbox"
                    id="offlineAlarmEnable"
                    name="offlineAlarmEnable"
                    checked={this.state.formDevice.offlineAlarmEnable}
                    onChange={this.validateOnChange}
                  />
                  <label
                    htmlFor="offlineAlarmEnable"
                    className="checkbox-label"
                  >
                    <span className="ui" />
                  </label>
                  <label className="text-muted">Enable</label>
                </p>
              </div>

              <div className="enableCheckbox d-flex justify-content-start algin-items-center flex-column form-group pt-2">
                <h6 className="text-muted text-center">Equipment Status</h6>
                <p className="pt-2">
                  <label className="text-muted">Inactive</label>
                  <Input
                    type="checkbox"
                    id="equipmentStatus"
                    name="equipmentStatus"
                    checked={this.state.formDevice.equipmentStatus}
                    onChange={this.validateOnChange}
                  />
                  <label htmlFor="equipmentStatus" className="checkbox-label">
                    <span className="ui" />
                  </label>
                  <label className="text-muted">Active</label>
                </p>
              </div>
            </div>
            {/* {formDevice.maSensor && (
              <>
                <hr style={{ margin: "2rem 0.5rem" }} />
                <div className="d-flex justify-content-between">
                  <h4>4...20mA Sensor Configuration</h4>
                  <div>
                    {/* <p style={{ marginLeft: "6rem" }}>Calculation: 4mA = ...</p> 
                  </div>
                  <div>
                    {/* <p style={{ marginRight: "3rem" }}>
                      Calculation: 20mA = ...
                    </p> 
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="w-100 mr-2">
                    {this.state.sensorConfiguration.map((val) => (
                      <div className="locations" style={{ overflow: "hidden" }}>
                        <div
                          className={`locationDiv align-items-center d-flex location-device-info flex-row ${
                            formDevice.maSensorType === val.id
                              ? "activeLocation"
                              : ""
                          }`}
                          key={val.id}
                          onClick={() =>
                            this.setState({
                              formDevice: {
                                ...this.state.formDevice,
                                maSensorType: val.id,
                              },
                            })
                          }
                        >
                          <div className="w-70 d-flex flex-row">
                            <p className="pl-1 pr-1 m-0 w-100 text-uppercase font-weight-bold">
                              {val.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="w-100 mr-2">
                    {maSensorTypeValueDropdown.length &&
                      maSensorTypeValueDropdown[0].values.map((val) => (
                        <div
                          className="locations"
                          style={{ overflow: "hidden" }}
                        >
                          <div
                            className={`locationDiv align-items-center d-flex location-device-info flex-row ${
                              formDevice.maSensorTypeValue === val
                                ? "activeLocation"
                                : ""
                            }`}
                            key={val}
                            onClick={() =>
                              this.setState({
                                formDevice: {
                                  ...this.state.formDevice,
                                  maSensorTypeValue: val,
                                },
                              })
                            }
                          >
                            <div className="w-70 d-flex flex-row">
                              <p className="pl-1 pr-1 m-0 w-100  font-weight-bold">
                                {val}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div style={{ width: "150%" }}>
                    <div className="form-group">
                      <label className="text-muted">
                        Calculation: 4mA ={" "}
                        {!!this.state.formDevice.conversionFactor
                          ? `${this.state.formDevice.conversionFactor} ${this.state.formDevice.maSensorTypeValue}`
                          : "..."}
                      </label>
                      <div className="input-group with-focus">
                        <Input
                          type="number"
                          name="conversionFactor"
                          placeholder="Enter 4mA value"
                          onChange={(e) =>
                            this.setState({
                              formDevice: {
                                ...this.state.formDevice,
                                conversionFactor: e.target.value,
                              },
                            })
                          }
                          value={this.state.formDevice.conversionFactor || ""}
                          minLength="2"
                          data-param={30}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="text-muted">
                        Calculation: 20mA ={" "}
                        {!!this.state.formDevice.conversionFactorTwo
                          ? `${this.state.formDevice.conversionFactorTwo} ${this.state.formDevice.maSensorTypeValue}`
                          : "..."}
                      </label>
                      <div className="input-group with-focus">
                        <Input
                          type="number"
                          name="conversionFactorTwo"
                          placeholder="Enter 20mA value"
                          onChange={(e) =>
                            this.setState({
                              formDevice: {
                                ...this.state.formDevice,
                                conversionFactorTwo: e.target.value,
                              },
                            })
                          }
                          value={
                            this.state.formDevice.conversionFactorTwo || ""
                          }
                          minLength="2"
                          data-param={30}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )} */}
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <div>
              <Button color="info" type="submit" className="mr-2">
                {mode === "Add" ? "Add" : "Save"}
              </Button>
              <Button color="secondary" type="button" onClick={toggleModal}>
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gatewayList: state.gateways.gatewayList,
    locations: state.locationsDevices.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDevice: (data) => dispatch(actions.addDevice(data)),
    editDevice: (data) => dispatch(actions.editDevice(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditDevice);
