import React, { Component } from "react";
import { ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../stores/actions/index";
import {
  fieldHasError,
  validateOnChange,
  onSubmit,
} from "../../helpers/formValidators";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { showToast } from "../../helpers/toast.js";
import Select from "react-select";
import { selectStyle } from "../../config/selectStyle";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AlertModal } from "../Common/AlertModal";
import "antd/dist/antd.css";
import { convertToFahrenheit } from "../../utils/celsiusToFahrenheit.js";
import { convertToCelcius } from "../../utils/convertToCelsius.js";

const tempMarks = {
  "-200": <strong>-200</strong>,
  200: <strong>200</strong>,
};

const fahrenheitTempMarks = {
  "-328": <strong>-328</strong>,
  392: <strong>392</strong>,
};

const humMarks = {
  0: <strong>0</strong>,
  100: <strong>100</strong>,
};
const co2Marks = {
  0: <strong>0</strong>,
  20: <strong>20</strong>,
};
// const ln2LevelMarks = {
//   0: <strong>0</strong>,
//   100: <strong>100</strong>,
// };
// const ln2TempMarks = {
//   "-200": <strong>-200</strong>,
//   200: <strong>200</strong>,
// };
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
class AlertProfiles extends Component {
  countyCodeList = ["+44", "+91", "+1"];
  state = {
    mode: "Add",
    submitDisabled: false,
    innerWidth: false,
    formDeviceAlarm: {
      alarmName: "",
      deviceId: null,
      devices: [],
      adminEnable: true,
      enable: true,
      emailEnable: false,
      textEnable: false,
      callEnable: false,
      sentToAllEnable: false,
      tempEnabled: true,
      shtTEnabled: true,
      humidityEnabled: true,
      carbonEnabled: true,
      ln2LevelEnabled: true,
      ln2TempEnabled: true,
      waterLevelEnabled: true,
      minTemp: 0,
      maxTemp: 50,
      minShtT: 0,
      maxShtT: 50,
      minHumidity: 0,
      maxHumidity: 50,
      minCarbon: 0,
      maxCarbon: 50,
      minLn2Level: -3000,
      maxLn2Level: 3000,
      minLn2Temp: -200,
      maxLn2Temp: 200,
      users: [],
      user: {
        userId: "",
        email: "",
        countryCode: "",
        phoneNumber: "",
      },
    },
    profileId: "",
    ln2LevelMin: -3000,
    ln2LevelMax: 3000,
    ln2LevelValue: [-3000, 3000],
    ln2TempValue: [-200, 200],
    ln2TempMin: -200,
    ln2TempMax: 200,
    tempMin:
      this.props.temperatureUnit === "Celsius"
        ? -200
        : convertToFahrenheit(-200),
    tempMax:
      this.props.temperatureUnit === "Celsius" ? 200 : convertToFahrenheit(200),
    humMin: 0,
    humMax: 100,
    co2Min: 0,
    co2Max: 20,
    tempValue:
      this.props.temperatureUnit === "Celsius"
        ? [-200, 200]
        : [convertToFahrenheit(-200), convertToFahrenheit(200)],
    shtValue:
      this.props.temperatureUnit === "Celsius"
        ? [-200, 200]
        : [convertToFahrenheit(-200), convertToFahrenheit(200)],
    carbonValue: [0, 20],
    humidityValue: [0, 100],
    selectedUser: {
      id: "",
      name: "",
    },
    selectedDevices: {
      id: "",
      name: "",
    },
    alarmDelay: { id: 0, name: 0 },
    alarmFrequency: { id: 15, name: 15 },
    isOneTime: false,
    dropdownOpen: false,
    showAlert: false,
    typingTimeout: 0,
    dropDownUsers: [],
    dropDownDevices: [],
    spillSensorDevices: [],
    alarmDelayData: [
      { id: 0, name: 0 },
      { id: 5, name: 5 },
      { id: 10, name: 10 },
      { id: 15, name: 15 },
      { id: 20, name: 20 },
      { id: 30, name: 30 },
      { id: 40, name: 40 },
      { id: 50, name: 50 },
      { id: 60, name: 60 },
    ],
    alarmFrequencyData: [
      { id: 15, name: 15 },
      { id: 30, name: 30 },
      { id: 45, name: 45 },
      { id: 60, name: 60 },
      { id: 75, name: 75 },
      { id: 90, name: 90 },
      { id: 105, name: 105 },
      { id: 120, name: 120 },
    ],
    selectedTimezone: { id: "Etc/UTC", currentTimeFormat: "Etc/UTC" },
  };
  componentDidUpdate(prevProps) {
    const {
      tempEnabled,
      shtTEnabled,
      humidityEnabled,
      carbonEnabled,
      ln2LevelEnabled,
      waterLevelEnabled,
    } = this.state.formDeviceAlarm;
    if (prevProps.users !== this.props.users && this.props.users) {
      this.setState({
        dropDownUsers: this.props.users,
      });
    }
    if (
      prevProps.allDevices !== this.props.allDevices &&
      this.props.allDevices
    ) {
      this.setState({
        dropDownDevices:
          typeof this.props.allDevices === "object"
            ? this.props.allDevices
            : [],
      });
    }
    if (prevProps.success !== this.props.success && this.props.success) {
      showToast(this.props.success, "success");

      setTimeout(() => {
        this.props.toggleModal();
      }, 1000);
    }
    if (prevProps.error !== this.props.error && this.props.error) {
      showToast(this.props.error, "error");
    }

    if (
      !tempEnabled &&
      !humidityEnabled &&
      !shtTEnabled &&
      !carbonEnabled &&
      !ln2LevelEnabled &&
      waterLevelEnabled &&
      !this.state.spillSensorDevices.length
    ) {
      const spillSensorDevices =
        this.state.dropDownDevices &&
        this.state.dropDownDevices.filter(
          (val) =>
            val.latestData &&
            val.latestData.nodeData &&
            val.latestData.nodeData.Node_Type === 2
        );
      this.setState({ spillSensorDevices });
    }
  }
  componentDidMount() {
    const {
      modalData,
      allDevices,
      users,
      getAllDevices,
      getUserList,
    } = this.props;
    if (!allDevices) {
      getAllDevices();
    } else {
      this.setState({
        dropDownDevices:
          typeof this.props.allDevices === "object"
            ? this.props.allDevices
            : [],
      });
    }

    if (typeof users === "object" && !users.length) {
      getUserList();
    } else {
      this.setState({
        dropDownUsers: users,
      });
    }

    if (window.innerWidth < 480) {
      this.setState({ innerWidth: true });
    }

    if (modalData && modalData.data) {
      const { data } = modalData;

      const celcius = "°C";
      const fahrenheit = "°F";
      const percentage = "%";

      this.setState({
        mode: "Edit",
        formDeviceAlarm: {
          ...this.state.formDeviceAlarm,
          adminEnable: data.adminEnable,
          enable: data.enable && data.enable !== "Disabled" ? true : false,
          alarmName: data.profileName,
          deviceId: data.deviceId,
          tempEnabled: data.tempEnabled,
          shtTEnabled: data.shtTEnabled,
          humidityEnabled: data.humidityEnabled,
          carbonEnabled: data.carbonEnabled,
          textEnable: data.phoneEnable,
          emailEnable: data.emailEnable,
          callEnable: data.callEnable,
          ln2LevelEnabled: data.pressureEnabled,
          minLn2Level: data.minPressure,
          maxLn2Level: data.maxPressure,
          devices: data.deviceInfo.map((val) => {
            return {
              id: val.deviceId,
              ...val,
            };
          }),
          minTemp:
            this.props.temperatureUnit === "Celsius"
              ? data.minTemp && data.tempEnabled
                ? parseFloat(data.minTemp.split(celcius)[0])
                : -200
              : parseFloat(data.minTemp.split(fahrenheit)[0]),
          maxTemp:
            this.props.temperatureUnit === "Celsius"
              ? data.maxTemp && data.tempEnabled
                ? parseFloat(data.maxTemp.split(celcius)[0])
                : 200
              : parseFloat(data.maxTemp.split(fahrenheit)[0]),
          minShtT:
            this.props.temperatureUnit === "Celsius"
              ? data.minShtT && data.shtTEnabled
                ? parseFloat(data.minShtT)
                : -200
              : parseFloat(data.minShtT),
          maxShtT:
            this.props.temperatureUnit === "Celsius"
              ? data.maxShtT && data.shtTEnabled
                ? parseFloat(data.maxShtT)
                : 200
              : parseFloat(data.maxShtT),
          minHumidity:
            data.minHumidity && data.humidityEnabled
              ? parseFloat(data.minHumidity.split(percentage)[0])
              : 0,
          maxHumidity:
            data.maxHumidity && data.humidityEnabled
              ? parseFloat(data.maxHumidity.split(percentage)[0])
              : 50,
          minCarbon:
            data.minCarbon && data.carbonEnabled
              ? parseFloat(data.minCarbon.split(percentage)[0])
              : 0,
          maxCarbon:
            data.maxCarbon && data.carbonEnabled
              ? parseFloat(data.maxCarbon.split(percentage)[0])
              : 50,
          users: data.alarmUsers,
          // ln2LevelEnabled: data.lnlvlEnabled,
          ln2TempEnabled: data.lntmpEnabled,
          waterLevelEnabled:
            data.waterEnabled && data.waterEnabled !== "Enabled" ? false : true,
          minLn2Level: data.minLnlvl,
          maxLn2Level: data.maxLnlvl,
          minLn2Temp: data.minLntmp,
          maxLn2Temp: data.maxLntmp,
        },
        profileId: data.profileId,
        tempValue:
          this.props.temperatureUnit === "Celsius"
            ? [
                data.minTemp && data.tempEnabled
                  ? parseFloat(data.minTemp.split(celcius)[0])
                  : -200,
                data.maxTemp && data.tempEnabled
                  ? parseFloat(data.maxTemp.split(celcius)[0])
                  : 200,
              ]
            : [
                data.minTemp && data.tempEnabled
                  ? parseFloat(data.minTemp.split(fahrenheit)[0])
                  : -328,
                data.maxTemp && data.tempEnabled
                  ? parseFloat(data.maxTemp.split(fahrenheit)[0])
                  : 392,
              ],
        shtValue:
          this.props.temperatureUnit === "Celsius"
            ? [
                data.minShtT && data.shtTEnabled
                  ? parseFloat(data.minShtT)
                  : -200,
                data.maxShtT && data.shtTEnabled
                  ? parseFloat(data.maxShtT)
                  : 200,
              ]
            : [
                data.minShtT && data.shtTEnabled
                  ? parseFloat(data.minShtT)
                  : -328,
                data.maxShtT && data.shtTEnabled
                  ? parseFloat(data.maxShtT)
                  : 392,
              ],
        carbonValue: [
          data.minCarbon && data.carbonEnabled
            ? parseFloat(data.minCarbon.split(percentage)[0])
            : 0,
          data.maxCarbon && data.carbonEnabled
            ? parseFloat(data.maxCarbon.split(percentage)[0])
            : 50,
        ],
        humidityValue: [
          data.minHumidity && data.humidityEnabled
            ? parseFloat(data.minHumidity.split(percentage)[0])
            : 0,
          data.maxHumidity && data.humidityEnabled
            ? parseFloat(data.maxHumidity.split(percentage)[0])
            : 50,
        ],
        // ln2LevelValue: [data.minLnlvl, data.maxLnlvl],
        // ln2TempValue: [data.minLntmp, data.maxLntmp],
        alarmDelay: !!data
          ? { id: data.delay / 60, name: data.delay / 60 }
          : { id: 0, name: 0 },
        alarmFrequency: {
          id: data.frequency,
          name: data.frequency,
        },
        isOneTime: !!data.oneTime,
      });
    }

    if (modalData && modalData.deviceId) {
      let users = [];
      const { deviceUsers } = modalData.currentDevice;
      if (deviceUsers && deviceUsers.length) {
        if (modalData && modalData.openMode !== "add") {
          modalData.currentDevice.deviceUsers.forEach((user) => {
            users.push(this.props.users.filter((u) => u.id === user.id)[0]);
          });

          users = users.filter((user) => users.name !== null);
        }
      }
      this.setState({
        formDeviceAlarm: {
          ...this.state.formDeviceAlarm,
          deviceId: modalData.deviceId,
          users: [...users],
        },
      });
    }
  }

  toggleDropDown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  handleAlarmDelay = (selectedAlarmValue) => {
    if (!!selectedAlarmValue) {
      this.setState({
        alarmDelay: selectedAlarmValue,
      });
    }
  };

  handleAlarmFrequency = (selectedAlarmValue) => {
    if (!!selectedAlarmValue) {
      this.setState({
        alarmFrequency: selectedAlarmValue,
      });
    }
  };

  handleSwitch = (checked) => {
    this.setState({
      isOneTime: checked,
    });
  };

  handleChangeUser = (selectedValue) => {
    if (!!selectedValue && selectedValue.name) {
      const isExisting = this.state.formDeviceAlarm.users.find(function(el) {
        return el.email === selectedValue.email;
      });
      if (!isExisting) {
        this.setState(
          {
            selectedValue,
            formDeviceAlarm: {
              ...this.state.formDeviceAlarm,
              users: [
                ...this.state.formDeviceAlarm.users,
                {
                  email: selectedValue.email,
                  countryCode: selectedValue.countryCode,
                  phoneNumber: selectedValue.phoneNumber,
                  userId: selectedValue.id,
                },
              ],
            },
          },
          () => {
            const array = this.props.users.filter((el) => {
              return !this.state.formDeviceAlarm.users.includes(el);
            });

            this.setState({
              dropDownUsers: array,
            });
            // const scrollDiv = document.getElementById('modal-bottom-content');
            // if (scrollDiv) {
            //   scrollDiv.scrollTop = 0;
            // }
          }
        );
      } else {
        showToast("User already exists", "error");
      }
    }
  };

  handleChangeDevice = (selectedValue) => {
    if (!!selectedValue && selectedValue.deviceId) {
      const isExisting = this.state.formDeviceAlarm.devices.filter(
        (el) => el.id === selectedValue.id
      );

      if (!isExisting.length) {
        this.setState(
          {
            selectedValue,
            formDeviceAlarm: {
              ...this.state.formDeviceAlarm,
              devices: [...this.state.formDeviceAlarm.devices, selectedValue],
            },
          },
          () => {
            const array = this.props.allDevices.filter((el) => {
              return !this.state.formDeviceAlarm.devices.includes(el);
            });

            this.setState({
              dropDownDevices: array,
            });
            // const scrollDiv = document.getElementById('modal-bottom-content');
            // if (scrollDiv) {
            //   scrollDiv.scrollTop = 0;
            // }
          }
        );
      } else {
        showToast("Device already exists", "error");
      }
    }
  };

  isUserSelected = (option) => {
    return this.state.selectedUser.id === option.id ? true : false;
  };

  isDeviceSelected = (option) => {
    return this.state.selectedDevices.id === option.id ? true : false;
  };

  addUser = () => {
    if (
      !!this.state.formDeviceAlarm.user.email &&
      !!this.state.formDeviceAlarm.user.phoneNumber
    ) {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          this.state.formDeviceAlarm.user.email
        ) ||
        !this.state.formDeviceAlarm.user.phoneNumber
      ) {
        showToast(
          "Please Enter Valid Email or Phone number for a user",
          "error"
        );
        return false;
      }

      let phone = this.state.formDeviceAlarm.user.phoneNumber;
      let countryCode = this.state.formDeviceAlarm.user.countryCode.slice(1);
      phone = phone.slice(countryCode.length);
      const newUser = {
        userId: this.state.formDeviceAlarm.user.userId,
        email: this.state.formDeviceAlarm.user.email,
        countryCode: this.state.formDeviceAlarm.user.countryCode,
        phoneNumber: phone,
      };
      const user = {
        userId: "",
        email: "",
        countryCode: "",
        phoneNumber: "",
      };
      const isExisting = this.state.formDeviceAlarm.users.find(function(el) {
        return el.email === newUser.email;
      });
      if (!isExisting) {
        this.setState(
          {
            formDeviceAlarm: {
              ...this.state.formDeviceAlarm,
              users: [...this.state.formDeviceAlarm.users, newUser],
              user,
            },
          },
          () => {
            const scrollDiv = document.getElementById("modal-bottom-content");
            if (scrollDiv) {
              scrollDiv.scrollTop = 0;
            }
          }
        );
      } else {
        showToast("User already exists", "error");
        this.setState({
          formDeviceAlarm: {
            ...this.state.formDeviceAlarm,
            user,
          },
        });
      }
    } else {
      showToast("Please Enter Valid Email or Phone number for a user", "error");
      return false;
    }
  };

  removeUser = (removeIndex) => {
    const { formDeviceAlarm } = this.state;
    let { users } = formDeviceAlarm;
    let restUsers = users.filter((item, index) => index !== removeIndex);
    this.setState({
      formDeviceAlarm: {
        ...this.state.formDeviceAlarm,
        users: [...restUsers],
      },
      selectedUser: {
        id: "",
        name: "",
      },
    });
  };

  removeDevice = (removeIndex) => {
    const { formDeviceAlarm } = this.state;
    let { devices } = formDeviceAlarm;
    let restDevices = devices.filter((item, index) => index !== removeIndex);
    this.setState({
      formDeviceAlarm: {
        ...this.state.formDeviceAlarm,
        devices: [...restDevices],
      },
      selectedDevices: {
        id: "",
        name: "",
      },
    });
  };

  saveAlertProfileFn = (payload) => {
    this.setState({
      submitDisabled: true,
    });
    const { saveAlertProfiles } = this.props;
    !this.state.submitDisabled && saveAlertProfiles(payload);
    setTimeout(() => {
      this.setState({
        submitDisabled: false,
      });
    }, 2000);
  };

  editAlertProfileFn = (payload) => {
    this.setState({
      submitDisabled: true,
    });

    const { editAlertProfile } = this.props;
    !this.state.submitDisabled && editAlertProfile({ payload });

    setTimeout(() => {
      this.setState({
        submitDisabled: false,
      });
    }, 2000);
  };

  validateOnChange = (event) => {
    const state = validateOnChange(event, this.state);
    this.setState(state);
  };

  validateUserChange = (event) => {
    const input = event.target;
    const value = input.value;
    // const result = FormValidator.validate(input);
    const { formDeviceAlarm } = this.state;
    let { user } = formDeviceAlarm;
    user = {
      ...user,
      [input.name]: value,
    };
    this.setState({
      formDeviceAlarm: {
        ...this.state.formDeviceAlarm,
        user,
      },
    });
  };

  onSubmit = (e) => {
    const state = onSubmit(e, this.state);

    e.preventDefault();

    if (!state.hasError) {
      const { dashboard } = this.props;
      const { userInfo } = dashboard;
      let userRole = "";
      if (userInfo) {
        const { role } = userInfo;
        userRole = role;
      }
      if (!state.formDeviceAlarm.alarmName) {
        showToast("Please add Profile Name", "error");
        return false;
      }
      const validationCheck =
        state.formDeviceAlarm.emailEnable ||
        state.formDeviceAlarm.textEnable ||
        state.formDeviceAlarm.callEnable;
      // this.setState(state);
      const users = [];
      state.formDeviceAlarm.users.forEach((user) => {
        const tempUser = {
          userId: user.userId,
          email: user.email,
          countryCode: user.countryCode,
          phoneNumber: user.phoneNumber,
        };
        users.push(tempUser);
      });
      const savedDevices = [];
      state.formDeviceAlarm.devices.forEach((device) => {
        const tempDevice = {
          deviceId: device.id,
        };
        savedDevices.push(tempDevice);
      });

      if (savedDevices.length === 0) {
        showToast("Atleast one device is required to select", "error");
        return false;
      }
      if (state.formDeviceAlarm.alarmName.length > 30) {
        showToast(
          "Profile name shouldn't be longer than 30 characters",
          "error"
        );
        return false;
      }
      if (!validationCheck) {
        showToast(
          "Select atleast one toggle either Email or Text when Alarm is enabled",
          "error"
        );
        return false;
      }
      //   if (state.tempValue.length !== 0) {
      //     if (state.tempValue[0].length === 0) {
      //       showToast("Please enter value for Min Temp", "error");
      //       return false;
      //     } else if (state.tempValue[1].length === 0) {
      //       showToast("Please enter value for Max Temp", "error");
      //       return false;
      //     }
      //     if (Number(state.tempValue[0]) > Number(state.tempValue[1])) {
      //       showToast(
      //         `Max Temp(${state.tempValue[1]}) Should be greater than Min Temp(${state.tempValue[0]})`,
      //         "error"
      //       );
      //       return false;
      //     }
      //   }
      //   if (state.humidityValue.length !== 0) {
      //     if (state.humidityValue[0].length === 0) {
      //       showToast("Please enter value for Min Humidity", "error");
      //       return false;
      //     } else if (state.humidityValue[1].length === 0) {
      //       showToast("Please enter value for Max Humidity", "error");
      //       return false;
      //     }
      //     if (Number(state.humidityValue[0]) > Number(state.humidityValue[1])) {
      //       showToast(
      //         `Max Temp(${state.humidityValue[1]}) Should be greater than Min Temp(${state.humidityValue[0]})`,
      //         "error"
      //       );
      //       return false;
      //     }
      //   }
      //   if (state.carbonValue.length !== 0) {
      //     if (state.carbonValue[0].length === 0) {
      //       showToast("Please enter value for Min Carbon", "error");
      //       return false;
      //     } else if (state.carbonValue[1].length === 0) {
      //       showToast("Please enter value for Max Carbon", "error");
      //       return false;
      //     }
      //     if (Number(state.carbonValue[0]) > Number(state.carbonValue[1])) {
      //       showToast(
      //         `Max Temp(${state.carbonValue[1]}) Should be greater than Min Temp(${state.carbonValue[0]})`,
      //         "error"
      //       );
      //       return false;
      //     }
      //   }
      //   if (state.ln2LevelValue.length !== 0) {
      //     if (state.ln2LevelValue[0].length === 0) {
      //       showToast("Please enter value for Min Carbon", "error");
      //       return false;
      //     } else if (state.ln2LevelValue[1].length === 0) {
      //       showToast("Please enter value for Max Carbon", "error");
      //       return false;
      //     }
      //     if (Number(state.ln2LevelValue[0]) > Number(state.ln2LevelValue[1])) {
      //       showToast(
      //         `Max Temp(${state.ln2LevelValue[1]}) Should be greater than Min Temp(${state.ln2LevelValue[0]})`,
      //         "error"
      //       );
      //       return false;
      //     }
      //   }
      //   if (state.ln2TempValue.length !== 0) {
      //     if (state.ln2TempValue[0].length === 0) {
      //       showToast("Please enter value for Min Carbon", "error");
      //       return false;
      //     } else if (state.ln2TempValue[1].length === 0) {
      //       showToast("Please enter value for Max Carbon", "error");
      //       return false;
      //     }
      //     if (Number(state.ln2TempValue[0]) > Number(state.ln2TempValue[1])) {
      //       showToast(
      //         `Max Temp(${state.ln2TempValue[1]}) Should be greater than Min Temp(${state.ln2TempValue[0]})`,
      //         "error"
      //       );
      //       return false;
      //     }
      //   }
      const params = {
        profileName: state.formDeviceAlarm.alarmName,
        name: state.formDeviceAlarm.alarmName,
        // deviceId: state.formDeviceAlarm.deviceId,
        adminEnable: state.formDeviceAlarm.adminEnable ? true : false,
        emailEnable: state.formDeviceAlarm.emailEnable ? true : false,
        phoneEnable: state.formDeviceAlarm.textEnable ? true : false,
        callEnable: state.formDeviceAlarm.callEnable ? true : false,
        enable: state.formDeviceAlarm.enable ? true : false,
        tempEnabled: state.formDeviceAlarm.tempEnabled ? true : false,
        shtTEnabled: state.formDeviceAlarm.shtTEnabled ? true : false,
        humidityEnabled: state.formDeviceAlarm.humidityEnabled ? true : false,
        carbonEnabled: state.formDeviceAlarm.carbonEnabled ? true : false,
        pressureEnabled: state.formDeviceAlarm.ln2LevelEnabled,
        waterEnabled: state.formDeviceAlarm.waterLevelEnabled,
        minTemp:
          this.props.temperatureUnit === "Celsius"
            ? state.tempValue[0]
            : convertToCelcius(state.tempValue[0]),
        maxTemp:
          this.props.temperatureUnit === "Celsius"
            ? state.tempValue[1]
            : convertToCelcius(state.tempValue[1]),
        minShtT:
          this.props.temperatureUnit === "Celsius"
            ? state.shtValue[0]
            : convertToCelcius(state.shtValue[0]),
        maxShtT:
          this.props.temperatureUnit === "Celsius"
            ? state.shtValue[1]
            : convertToCelcius(state.shtValue[1]),
        minHumidity: state.humidityValue[0],
        maxHumidity: state.humidityValue[1],
        minCarbon: state.carbonValue[0],
        maxCarbon: state.carbonValue[1],
        minPressure: state.ln2LevelValue[0],
        maxPressure: state.ln2LevelValue[1],
        users: users,
        devices: savedDevices,
        delay: !!this.state.alarmDelay.name
          ? this.state.alarmDelay.name * 60
          : 0,
        frequency: !!this.state.alarmFrequency.name
          ? this.state.alarmFrequency.name
          : 15,
        one_time: this.state.isOneTime,
        // timeSettings: {
        //   monday: [
        //     {
        //       start: "09:00",
        //       end: "10:00",
        //       emailEnable: true,
        //       textEnable: true,
        //     },
        //   ],
        //   tuesday: [
        //     {
        //       start: "09:00",
        //       end: "17:00",
        //       emailEnable: true,
        //       textEnable: true,
        //     },
        //   ],
        //   wednesday: [
        //     {
        //       start: "09:00",
        //       end: "17:00",
        //       emailEnable: true,
        //       textEnable: true,
        //     },
        //   ],
        //   thursday: [
        //     {
        //       start: "09:00",
        //       end: "17:00",
        //       emailEnable: true,
        //       textEnable: true,
        //     },
        //   ],
        //   friday: [
        //     {
        //       start: "09:00",
        //       end: "17:00",
        //       emailEnable: true,
        //       textEnable: true,
        //     },
        //   ],
        //   saturday: [
        //     {
        //       start: "09:00",
        //       end: "17:00",
        //       emailEnable: true,
        //       textEnable: true,
        //     },
        //   ],
        //   sunday: [
        //     {
        //       start: "09:00",
        //       end: "17:00",
        //       emailEnable: true,
        //       textEnable: true,
        //     },
        //   ],
        // },
      };
      if (state.mode === "Edit") {
        this.editAlertProfileFn({ ...params, profileId: state.profileId });
        this.props.getAlertProfileList();
      } else {
        this.saveAlertProfileFn(params);
        this.props.getAlertProfileList();
      }
    } else {
      if (!state.formDeviceAlarm.alarmName) {
        showToast("Please enter Profile name", "error");
        return false;
      }
    }
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return fieldHasError(this.state, formName, inputName, method);
  };

  tempUpdated(value) {
    this.setState({
      tempValue: [value[0].toFixed(1), value[1].toFixed(1)],
    });
  }

  shtTempUpdated(value) {
    this.setState({
      shtValue: [value[0].toFixed(1), value[1].toFixed(1)],
    });
  }

  humidityUpdated(value) {
    this.setState({
      humidityValue: [value[0], value[1]],
    });
  }

  carbonUpdated(value) {
    this.setState({
      carbonValue: [value[0], value[1]],
    });
  }
  ln2LevelUpdated(value) {
    this.setState({
      ln2LevelValue: [value[0], value[1]],
    });
  }
  ln2TempUpdated(value) {
    this.setState({
      ln2TempValue: [value[0], value[1]],
    });
  }

  countryCodeChange = (countryCode) => {
    this.setState({
      ...this.state,
      formDeviceAlarm: {
        ...this.state.formDeviceAlarm,
        user: {
          ...this.state.formDeviceAlarm.user,
          countryCode,
        },
      },
    });
  };

  handlePhoneChange = (value, country, e, formattedValue) => {
    this.setState({
      formDeviceAlarm: {
        ...this.state.formDeviceAlarm,
        user: {
          ...this.state.formDeviceAlarm.user,
          phoneNumber: value,
          countryCode: "+" + country.dialCode.toString(),
        },
      },
    });
  };

  handleInputChange = (e, stateName) => {
    const self = this;
    const { typingTimeout } = self.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const prevState = self.state[stateName];
    const { value, name } = e.target;

    let newValue = value;

    const array =
      name === "startIndex"
        ? [newValue, prevState[1]]
        : [prevState[0], newValue];

    this.setState({
      [stateName]: array,
    });
  };

  render() {
    const {
      toggleModal,
      dashboard,
      allDevices,
      temperatureUnit,
      voiceCall,
    } = this.props;
    const { userInfo } = dashboard;
    let userRole = "";
    if (userInfo) {
      const { role } = userInfo;
      userRole = role;
    }
    const {
      //   mode,
      tempValue,
      shtValue,
      humidityValue,
      ln2LevelValue,
      ln2LevelMarks,
      //   ln2TempValue,
      carbonValue,
      formDeviceAlarm,
      dropDownUsers,
      dropDownDevices,
      spillSensorDevices,
    } = this.state;

    const spillSensorCheck =
      !formDeviceAlarm.tempEnabled &&
      !formDeviceAlarm.humidityEnabled &&
      !formDeviceAlarm.shtTEnabled &&
      !formDeviceAlarm.carbonEnabled &&
      !formDeviceAlarm.ln2LevelEnabled &&
      formDeviceAlarm.waterLevelEnabled;

    const { user } = formDeviceAlarm;

    // const timeZonesWithUtc = getTimeZones({ includeUtc: true }).map((val) => {
    //   return {
    //     id: val.name,
    //     name: val.name,
    //     abbreviation: val.abbreviation,
    //     currentTimeFormat: val.currentTimeFormat,
    //   };
    // });
    // const timeZones = getTimeZones().map((val) => {
    //   return {
    //     id: val.name,
    //     name: val.name,
    //     abbreviation: val.abbreviation,
    //     currentTimeFormat: val.currentTimeFormat,
    //   };
    // });
    // const filteredTimezones = timeZonesWithUtc.filter(
    //   (eachVal) => eachVal.abbreviation === "UTC"
    // );

    return (
      <React.Fragment>
        <form className="mb-3" name="formDeviceAlarm" onSubmit={this.onSubmit}>
          <ModalHeader tag="strong" toggle={toggleModal}>
            Alert Profiles
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="text-muted">Profile Name</label>
              <div className="input-group with-focus">
                <Input
                  type="text"
                  name="alarmName"
                  placeholder="Enter profile name"
                  invalid={this.hasError(
                    "formDeviceAlarm",
                    "alarmName",
                    "required"
                  )}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={
                    !!this.state.formDeviceAlarm.alarmName.trim()
                      ? this.state.formDeviceAlarm.alarmName
                      : ""
                  }
                  minLength="2"
                />
                {this.hasError("formDeviceAlarm", "alarmName", "required") && (
                  <span className="invalid-feedback">Name is required</span>
                )}
              </div>
            </div>
            <div className="sliders alarm__sliders form-group">
              <div className="tempSlider mb-3">
                <div className="row w-100 no-gutters">
                  {/* <div className='slider-inputs-space col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center'>
                    <label htmlFor='startIndex' className='label-space'>
                    %
                    </label>
                    <label htmlFor='endIndex'>Max</label>
                    
                    <label htmlFor='endIndex'>%</label>
                  </div> */}

                  <div className="enableCheckbox d-flex col-lg-4 col-12 justify-content-start algin-items-center">
                    <p className="mt-3">
                      <span className="checkbox-span">Temperature</span>
                      <Input
                        type="checkbox"
                        id="tempEnabled"
                        name="tempEnabled"
                        checked={this.state.formDeviceAlarm.tempEnabled}
                        onChange={this.validateOnChange}
                      />
                      <label htmlFor="tempEnabled" className="checkbox-label">
                        <span className="ui" />
                      </label>
                    </p>
                  </div>
                  <div className="d-flex align-items-center col-lg-8 col-12">
                    <div className="mt-3 mr-2 d-flex w-17">
                      {/* <label htmlFor='startIndex'>Min</label> */}
                      <input
                        className="slider-input ml-1 mr-1 rounded"
                        type="number"
                        name="startIndex"
                        size="2"
                        value={tempValue[0]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => this.handleInputChange(e, "tempValue")}
                      />
                      <label htmlFor="startIndex" className="mr-1">
                        {temperatureUnit === "Celsius" ? "C" : "F"}
                      </label>
                    </div>
                    <div className={`w-${this.state.innerWidth ? 100 : 60}`}>
                      <div className="w-100 slider-pos mx-auto">
                        <Range
                          allowCross={false}
                          marks={
                            this.state.innerWidth
                              ? temperatureUnit === "Celsius"
                                ? tempMarks
                                : fahrenheitTempMarks
                              : {}
                          }
                          step={0.1}
                          tipProps={{ placement: "bottom", visible: false }}
                          tipFormatter={(value) =>
                            `${value} ${
                              temperatureUnit === "Celsius" ? "C" : "F"
                            }`
                          }
                          onChange={this.tempUpdated.bind(this)}
                          min={this.state.tempMin}
                          max={this.state.tempMax}
                          value={tempValue}
                          disabled={!this.state.formDeviceAlarm.tempEnabled}
                          // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                        />
                      </div>
                    </div>
                    <div className="mt-3 ml-1 d-flex">
                      {/* <label htmlFor='endIndex'>Max</label> */}
                      <input
                        className="slider-input ml-3 mr-1 rounded"
                        type="number"
                        name="endIndex"
                        size="2"
                        value={tempValue[1]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => this.handleInputChange(e, "tempValue")}
                      />
                      <label htmlFor="endIndex">
                        {temperatureUnit === "Celsius" ? "C" : "F"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tempSlider mb-3">
                <div className="row w-100 no-gutters">
                  <div className="enableCheckbox d-flex col-lg-4 col-12 justify-content-start algin-items-center">
                    <p className="mt-3">
                      <span className="checkbox-span">Thermocouple</span>
                      <Input
                        type="checkbox"
                        id="shtTEnabled"
                        name="shtTEnabled"
                        checked={this.state.formDeviceAlarm.shtTEnabled}
                        onChange={this.validateOnChange}
                      />
                      <label htmlFor="shtTEnabled" className="checkbox-label">
                        <span className="ui" />
                      </label>
                    </p>
                  </div>
                  <div className="d-flex align-items-center col-lg-8 col-12">
                    <div className="mt-3 mr-2 d-flex w-17">
                      {/* <label htmlFor='startIndex'>Min</label> */}
                      <input
                        className="slider-input ml-1 mr-1 rounded"
                        type="number"
                        name="startIndex"
                        size="2"
                        value={shtValue[0]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => this.handleInputChange(e, "shtValue")}
                      />
                      <label htmlFor="startIndex" className="mr-1">
                        {temperatureUnit === "Celsius" ? "C" : "F"}
                      </label>
                    </div>
                    <div className={`w-${this.state.innerWidth ? 100 : 60}`}>
                      <div className="w-100 slider-pos mx-auto">
                        <Range
                          allowCross={false}
                          marks={
                            this.state.innerWidth
                              ? temperatureUnit === "Celsius"
                                ? tempMarks
                                : fahrenheitTempMarks
                              : {}
                          }
                          step={0.1}
                          tipProps={{ placement: "bottom", visible: false }}
                          tipFormatter={(value) =>
                            `${value} ${
                              temperatureUnit === "Celsius" ? "C" : "F"
                            }`
                          }
                          onChange={this.shtTempUpdated.bind(this)}
                          min={this.state.tempMin}
                          max={this.state.tempMax}
                          value={shtValue}
                          disabled={!this.state.formDeviceAlarm.shtTEnabled}
                          // getTooltipPopupContainer={() => document.querySelector(".rc-slider-step")}
                        />
                      </div>
                    </div>
                    <div className="mt-3 ml-1 d-flex">
                      {/* <label htmlFor='endIndex'>Max</label> */}
                      <input
                        className="slider-input ml-3 mr-1 rounded"
                        type="number"
                        name="endIndex"
                        size="2"
                        value={shtValue[1]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => this.handleInputChange(e, "shtValue")}
                      />
                      <label htmlFor="endIndex">
                        {temperatureUnit === "Celsius" ? "C" : "F"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tempSlider mb-3">
                <div className="row w-100 no-gutters">
                  <div className="enableCheckbox d-flex col-lg-4 col-12 justify-content-start algin-items-center">
                    <p className="mt-3">
                      <span className="checkbox-span">Humidity</span>
                      <Input
                        type="checkbox"
                        id="humidityEnabled"
                        name="humidityEnabled"
                        checked={this.state.formDeviceAlarm.humidityEnabled}
                        onChange={this.validateOnChange}
                      />
                      <label
                        htmlFor="humidityEnabled"
                        className="checkbox-label"
                      >
                        <span className="ui" />
                      </label>
                    </p>
                  </div>
                  <div className="d-flex align-items-center col-lg-8 col-12">
                    <div className="mt-3 mr-2 d-flex w-17">
                      {/* <label htmlFor='startIndex'>Min</label> */}
                      <input
                        className="slider-input ml-1 mr-1 rounded"
                        type="number"
                        name="startIndex"
                        size="2"
                        value={humidityValue[0]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) =>
                          this.handleInputChange(e, "humidityValue")
                        }
                      />
                      <label htmlFor="startIndex" className="mr-1">
                        %
                      </label>
                    </div>
                    <div className={`w-${this.state.innerWidth ? 100 : 60}`}>
                      <div className="w-100 slider-pos mx-auto">
                        <Range
                          allowCross={false}
                          tipProps={{ visible: false, placement: "bottom" }}
                          marks={this.state.innerWidth ? humMarks : {}}
                          step={0.1}
                          tipFormatter={(value) => `${value}%`}
                          onChange={this.humidityUpdated.bind(this)}
                          value={humidityValue}
                          min={this.state.humMin}
                          max={this.state.humMax}
                          disabled={!this.state.formDeviceAlarm.humidityEnabled}
                        />
                      </div>
                    </div>
                    <div className="mt-3 ml-1 d-flex">
                      {/* <label htmlFor='endIndex'>Max</label> */}
                      <input
                        className="slider-input ml-3 mr-1 rounded"
                        type="number"
                        name="endIndex"
                        size="2"
                        value={humidityValue[1]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) =>
                          this.handleInputChange(e, "humidityValue")
                        }
                      />
                      <label htmlFor="endIndex">%</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tempSlider mb-3">
                <div className="row w-100 no-gutters">
                  <div className="enableCheckbox d-flex col-lg-4 col-12 justify-content-start algin-items-center">
                    <p className="mt-3">
                      <span className="checkbox-span">CO₂</span>
                      <Input
                        type="checkbox"
                        id="carbonEnabled"
                        name="carbonEnabled"
                        checked={this.state.formDeviceAlarm.carbonEnabled}
                        onChange={this.validateOnChange}
                      />
                      <label htmlFor="carbonEnabled" className="checkbox-label">
                        <span className="ui" />
                      </label>
                    </p>
                  </div>
                  <div className="d-flex align-items-center col-lg-8 col-12">
                    <div className="mt-3 mr-2 d-flex w-17">
                      {/* <label htmlFor='startIndex'>Min</label> */}
                      <input
                        className="slider-input ml-1 mr-1 rounded"
                        type="number"
                        name="startIndex"
                        size="2"
                        value={carbonValue[0]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) =>
                          this.handleInputChange(e, "carbonValue")
                        }
                      />
                      <label htmlFor="startIndex" className="mr-1">
                        %
                      </label>
                    </div>
                    <div className={`w-${this.state.innerWidth ? 100 : 60}`}>
                      <div className="w-100 slider-pos mx-auto">
                        <Range
                          allowCross={false}
                          tipProps={{ visible: false, placement: "bottom" }}
                          marks={this.state.innerWidth ? co2Marks : {}}
                          step={0.1}
                          tipFormatter={(value) => `${value}%`}
                          onChange={this.carbonUpdated.bind(this)}
                          value={carbonValue}
                          min={this.state.co2Min}
                          max={this.state.co2Max}
                          disabled={!this.state.formDeviceAlarm.carbonEnabled}
                          getTooltipPopupContainer={() =>
                            document.querySelector(".rc-slider-step")
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-3 ml-1 d-flex">
                      {/* <label htmlFor='endIndex'>Max</label> */}
                      <input
                        className="slider-input ml-3 mr-1 rounded"
                        type="number"
                        name="endIndex"
                        size="2"
                        value={carbonValue[1]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) =>
                          this.handleInputChange(e, "carbonValue")
                        }
                      />
                      <label htmlFor="endIndex">%</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <label className="text-muted">Add Contact</label> */}
            <div className="tempSlider mb-3">
              <div className="row w-100 no-gutters">
                <div className="enableCheckbox d-flex col-lg-4 col-12 justify-content-start algin-items-center">
                  <p className="mt-3">
                    <span className="checkbox-span">Pressure</span>
                    <Input
                      type="checkbox"
                      id="ln2LevelEnabled"
                      name="ln2LevelEnabled"
                      checked={this.state.formDeviceAlarm.ln2LevelEnabled}
                      onChange={this.validateOnChange}
                    />
                    <label htmlFor="ln2LevelEnabled" className="checkbox-label">
                      <span className="ui" />
                    </label>
                  </p>
                </div>
                <div className="d-flex align-items-center col-lg-8 col-12">
                  <div className="mt-3 mr-2 d-flex w-17">
                    {/* <label htmlFor="startIndex">Min</label> */}
                    <input
                      className="slider-input ml-1 mr-1 rounded"
                      type="number"
                      name="startIndex"
                      size="2"
                      value={ln2LevelValue[0]}
                      onFocus={(e) => e.target.select()}
                      onChange={(e) =>
                        this.handleInputChange(e, "ln2LevelValue")
                      }
                    />
                    <label htmlFor="startIndex" className="mr-1">
                      hPa
                    </label>
                  </div>
                  <div className={`w-${this.state.innerWidth ? 100 : 60}`}>
                    <div className="w-100 slider-pos mx-auto">
                      <Range
                        allowCross={false}
                        tipProps={{ visible: false, placement: "bottom" }}
                        marks={this.state.innerWidth ? ln2LevelMarks : {}}
                        step={0.1}
                        tipFormatter={(value) => `${value}hPa`}
                        onChange={this.ln2LevelUpdated.bind(this)}
                        value={ln2LevelValue}
                        min={this.state.ln2LevelMin}
                        max={this.state.ln2LevelMax}
                        disabled={!this.state.formDeviceAlarm.ln2LevelEnabled}
                        getTooltipPopupContainer={() =>
                          document.querySelector(".rc-slider-step")
                        }
                      />
                    </div>
                  </div>
                  <div className="mt-3 ml-1 d-flex">
                    <input
                      className="slider-input ml-3 mr-1 rounded"
                      type="number"
                      name="endIndex"
                      size="2"
                      value={ln2LevelValue[1]}
                      onFocus={(e) => e.target.select()}
                      onChange={(e) =>
                        this.handleInputChange(e, "ln2LevelValue")
                      }
                    />
                    <label htmlFor="endIndex">hPa</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row w-100 no-gutters">
              <div className="enableCheckbox d-flex col-lg-4 col-12 justify-content-start algin-items-center">
                <p className="mt-3">
                  <span className="checkbox-span">Spill Sensor</span>
                  <Input
                    type="checkbox"
                    id="waterLevelEnabled"
                    name="waterLevelEnabled"
                    checked={this.state.formDeviceAlarm.waterLevelEnabled}
                    onChange={this.validateOnChange}
                  />
                  <label htmlFor="waterLevelEnabled" className="checkbox-label">
                    <span className="ui" />
                  </label>
                </p>
              </div>
            </div>
            {/*
              <div className="tempSlider mb-3">
                <div className="row w-100 no-gutters">
                  <div className="enableCheckbox d-flex col-lg-4 col-12 justify-content-start algin-items-center">
                    <p className="mt-3">
                      <span className="checkbox-span">LN2 Temperature</span>
                      <Input
                        type="checkbox"
                        id="ln2TempEnabled"
                        name="ln2TempEnabled"
                        checked={this.state.formDeviceAlarm.ln2TempEnabled}
                        onChange={this.validateOnChange}
                      />
                      <label
                        htmlFor="ln2TempEnabled"
                        className="checkbox-label"
                      >
                        <span className="ui" />
                      </label>
                    </p>
                  </div>
                  {/*
                  <div className="d-flex align-items-center col-lg-8 col-12">
                    <div className="mt-3 mr-2 d-flex w-17">
                      <input
                        className="slider-input ml-1 mr-1 rounded"
                        type="number"
                        name="startIndex"
                        size="2"
                        value={ln2TempValue[0]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) =>
                          this.handleInputChange(e, "ln2TempValue")
                        }
                      />
                      <label htmlFor="startIndex" className="mr-1">
                        C
                      </label>
                    </div>
                    <div className={`w-${this.state.innerWidth ? 100 : 60}`}>
                      <div className="w-100 slider-pos mx-auto">
                        <Range
                          allowCross={false}
                          tipProps={{ visible: false, placement: "bottom" }}
                          marks={this.state.innerWidth ? ln2TempMarks : {}}
                          step={0.1}
                          tipFormatter={(value) => `${value}C`}
                          onChange={this.ln2TempUpdated.bind(this)}
                          value={ln2TempValue}
                          min={this.state.ln2TempMin}
                          max={this.state.ln2TempMax}
                          disabled={!this.state.formDeviceAlarm.ln2TempEnabled}
                          getTooltipPopupContainer={() =>
                            document.querySelector(".rc-slider-step")
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-3 ml-1 d-flex">
                      <input
                        className="slider-input ml-3 mr-1 rounded"
                        type="number"
                        name="endIndex"
                        size="2"
                        value={ln2TempValue[1]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) =>
                          this.handleInputChange(e, "ln2TempValue")
                        }
                      />
                      <label htmlFor="endIndex">C</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tempSlider mb-3">
                <div className="row w-100 no-gutters">
                  <div className="enableCheckbox d-flex col-lg-4 col-12 justify-content-start algin-items-center">
                    <p className="mt-3">
                      <span className="checkbox-span">Water Level</span>
                      <Input
                        type="checkbox"
                        id="waterLevelEnabled"
                        name="waterLevelEnabled"
                        checked={this.state.formDeviceAlarm.waterLevelEnabled}
                        onChange={this.validateOnChange}
                      />
                      <label
                        htmlFor="waterLevelEnabled"
                        className="checkbox-label"
                      >
                        <span className="ui" />
                      </label>
                    </p>
                  </div>
                </div>
              </div>*/}
            <div id="modal-bottom-content">
              {userRole === "super_user" && (
                <>
                  {this.state.formDeviceAlarm.users.length ? (
                    <div>
                      <label className="text-muted mt-2">Added Contacts</label>
                      <div className="locations alarmUsers mxHeight-100">
                        {this.state.formDeviceAlarm.users.map((user, index) => (
                          <div
                            className="locationDiv align-items-center d-flex flex-row overflow-auto"
                            key={index}
                            ref={index}
                          >
                            <div className="w-75 d-flex flex-column">
                              <p className="pl-1 pr-1 m-0 w-100 text-uppercase font-weight-bold">
                                {user && user.name ? user.name : ""}
                              </p>
                              <p className="pl-1 pr-1 m-0 w-100 font-weight-bold">
                                {user && user.email ? user.email : ""}
                              </p>
                              <p className="pl-1 pr-1 m-0 w-100 font-weight-bold">
                                {user && user.countryCode
                                  ? user.countryCode
                                  : ""}
                                -
                                {user && user.phoneNumber
                                  ? user.phoneNumber
                                  : ""}
                              </p>
                            </div>
                            <div className="w-25 text-right">
                              <button
                                className="transparentBtn"
                                type="button"
                                id="removeButton"
                                onClick={() => this.removeUser(index)}
                              >
                                <i className=" fas fa-trash mt-0"></i>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-2 form-group">
                    <div>
                      <label className="text-muted">Select existing user</label>
                      <Select
                        maxMenuHeight={170}
                        styles={selectStyle}
                        name="locationId"
                        value={this.state.selectedUser}
                        onChange={this.handleChangeUser}
                        options={dropDownUsers}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option}`}
                        isOptionSelected={(option) => {
                          this.isUserSelected(option);
                        }}
                      />
                    </div>
                    {/* <div className="mt-4">
                      <div className="form-group">
                        <label className="text-muted">Timezone</label>
                        <Select
                          styles={selectStyle}
                          name="timezone"
                          value={this.state.selectedTimezone}
                          onChange={(timezone) => {
                            this.setState({
                              selectedTimezone: timezone,
                            });
                          }}
                          options={[...filteredTimezones, ...timeZones]}
                          getOptionLabel={(option) =>
                            `${option.currentTimeFormat}`
                          }
                          getOptionValue={(option) => `${option}`}
                          // isOptionSelected={(option) =>
                          //   this.state.selectedTimezone === option.name || false
                          // }
                        />
                      </div>
                    </div> */}
                  </div>

                  {this.state.formDeviceAlarm.devices.length ? (
                    <div>
                      <label className="text-muted mt-2">Added Devices</label>
                      <div className="locations alarmUsers mxHeight-100">
                        {this.state.formDeviceAlarm.devices.map(
                          (device, index) => (
                            <div
                              className="locationDiv align-items-center d-flex flex-row overflow-auto"
                              key={index}
                              ref={index}
                            >
                              <div className="w-75 d-flex flex-column">
                                <p className="pl-1 pr-1 m-0 w-100 text-uppercase font-weight-bold">
                                  {device && device.name ? device.name : ""}
                                </p>
                                {/* <p className="pl-1 pr-1 m-0 w-100 font-weight-bold">
                                  {device && device.status ? device.status : ""}
                                </p> */}
                                {/* <p className="pl-1 pr-1 m-0 w-100 font-weight-bold">
                                  {device && device.location.name
                                    ? device.location.name
                                    : ""}
                                </p> */}
                              </div>
                              <div className="w-25 text-right">
                                <button
                                  className="transparentBtn"
                                  type="button"
                                  id="removeButton"
                                  onClick={() => this.removeDevice(index)}
                                >
                                  <i className=" fas fa-trash mt-0"></i>
                                </button>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-2 form-group">
                    <div>
                      <label className="text-muted">
                        Select existing devices
                      </label>
                      <Select
                        maxMenuHeight={170}
                        styles={selectStyle}
                        name="locationId"
                        value={this.state.selectedDevices}
                        onChange={this.handleChangeDevice}
                        options={
                          spillSensorCheck
                            ? spillSensorDevices
                            : dropDownDevices
                        }
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option}`}
                        isOptionSelected={(option) => {
                          this.isDeviceSelected(option);
                        }}
                      />
                    </div>
                  </div>
                  <label className="text-muted">Add new user</label>
                  <div className="mt-3 alarm_add_user">
                    <div className="form-group">
                      <label className="text-muted">Email</label>
                      <div className="input-group with-focus">
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={(event) => this.validateUserChange(event)}
                          value={user.email || ""}
                          minLength="2"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="text-muted">Phone Numbers</label>
                      <PhoneInput
                        className="w-100"
                        country="gb"
                        countryCodeEditable={false}
                        value={this.state.formDeviceAlarm.user.phoneNumber}
                        onChange={this.handlePhoneChange}
                      />
                    </div>
                    <Button
                      color="info"
                      className="btn-labeled mt-28 p-0"
                      type="button"
                      onClick={this.addUser.bind(this)}
                    >
                      <span className="btn-label left-0">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </span>
                    </Button>
                    <Button
                      color="info"
                      className="btn-labeled hidden-on-pc "
                      type="button"
                      onClick={this.addUser.bind(this)}
                    >
                      <span className="btn-label">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </span>
                      Add
                    </Button>
                  </div>
                </>
              )}
              <div className="mt-3 alarm_add_user alarm_delay">
                <div className="mt-2 form-group">
                  <label className="text-muted">Select Alarm Delay</label>
                  <Select
                    maxMenuHeight={170}
                    styles={selectStyle}
                    name="delay"
                    value={this.state.alarmDelay}
                    onChange={this.handleAlarmDelay}
                    options={this.state.alarmDelayData}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option}`}
                  />
                </div>
                <div className="mt-2 form-group">
                  <label className="text-muted">Select Alarm Frequency</label>
                  <Select
                    maxMenuHeight={170}
                    styles={selectStyle}
                    name="frequency"
                    value={this.state.alarmFrequency}
                    onChange={this.handleAlarmFrequency}
                    options={this.state.alarmFrequencyData}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option}`}
                  />
                </div>
                {/* <div style={{ margin: "auto" }}>
                  <div className="text-muted mb-1">One Time</div>
                  <Switch
                    onChange={this.handleSwitch}
                    checked={this.state.isOneTime}
                  />
                </div> */}
              </div>
            </div>
            <div className="alarm_add_user mt-3">
              <div className="col-sm-9 d-flex justify-content-center">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="emailEnable"
                      name="emailEnable"
                      checked={this.state.formDeviceAlarm.emailEnable}
                      onChange={this.validateOnChange}
                    />
                    <label htmlFor="emailEnable">
                      <span className="ui"></span>
                      <span className="checkbox-span">E-mail Alert</span>
                    </label>
                  </p>
                </div>
              </div>
              <div className="col-sm-9 d-flex justify-content-center">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="textEnable"
                      name="textEnable"
                      checked={this.state.formDeviceAlarm.textEnable}
                      onChange={this.validateOnChange}
                    />
                    <label htmlFor="textEnable">
                      <span className="ui"></span>
                      <span className="checkbox-span">Text Alert</span>
                    </label>
                  </p>
                </div>
              </div>
              {voiceCall && (
                <div className="col-sm-9 d-flex justify-content-center">
                  <div className="form-group enableCheckbox m-0">
                    <p>
                      <Input
                        type="checkbox"
                        id="callEnable"
                        name="callEnable"
                        checked={this.state.formDeviceAlarm.callEnable}
                        onChange={this.validateOnChange}
                      />
                      <label htmlFor="callEnable">
                        <span className="ui"></span>
                        <span className="checkbox-span">Voice Alert</span>
                      </label>
                    </p>
                  </div>
                </div>
              )}
              {/* <div className="col-sm-9 d-flex justify-content-center">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="sentToAllEnable"
                      name="sentToAllEnable"
                      checked={this.state.formDeviceAlarm.sentToAllEnable}
                      onChange={this.validateOnChange}
                    />
                    <label htmlFor="sentToAllEnable">
                      <span className="ui"></span>
                      <span className="checkbox-span">Alert to All</span>
                    </label>
                  </p>
                </div>
              </div> */}
              <div className="col-sm-9 d-flex justify-content-center">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="adminEnable"
                      name="adminEnable"
                      checked={this.state.formDeviceAlarm.adminEnable}
                      onChange={this.validateOnChange}
                    />
                    <label htmlFor="adminEnable">
                      <span className="ui"></span>
                      <span className="checkbox-span">Notify Admin</span>
                    </label>
                  </p>
                </div>
              </div>
              <div className="col-sm-9 d-flex justify-content-center mt-3">
                <div className="form-group enableCheckbox m-0">
                  <p>
                    <Input
                      type="checkbox"
                      id="enable"
                      name="enable"
                      checked={this.state.formDeviceAlarm.enable}
                      onChange={this.validateOnChange}
                    />
                    <label htmlFor="enable">
                      <span className="ui"></span>
                      <span className="checkbox-span">Alarm Enabled</span>
                    </label>
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid px-0">
              <div className="row">
                <div className="row col-lg-8 no-gutters"></div>
                <div className="d-flex justify-content-end col-lg-4">
                  <Button color="info" type="submit">
                    Submit
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    className="ml-2"
                    onClick={toggleModal}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <AlertModal
              isOpen={this.state.showAlert}
              modalAction={() => this.setState({ showAlert: false })}
              heading="Alert!"
              message={this.state.alertMessage || ""}
            />
          </ModalFooter>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users.userList,
    success: state.alertProfiles.success,
    error: state.alertProfiles.error,
    dashboard: state.dashboard.dashboard,
    allDevices: state.locationsDevices.allDevices,
    temperatureUnit: state.auth.temperatureUnit,
    voiceCall: state.auth.voiceCall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAlertProfiles: (data) => dispatch(actions.saveAlertProfile(data)),
    editAlertProfile: (data) => dispatch(actions.editAlertProfile(data)),
    getAlertProfileList: (data) => dispatch(actions.getAlertProfileList()),
    getAllDevices: () => dispatch(actions.getAllDevices()),
    getUserList: (data) => dispatch(actions.getUserList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertProfiles);
