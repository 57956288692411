import React, { Component } from "react";
import {
  convertDateFormat,
  capitalizeFirstLetter,
} from "../../utils/helperFunctions";
import { MDBBtn, MDBDataTable } from "mdbreact";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Pagination from "../Common/Pagination";

const columns = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 250,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 300,
  },
  {
    label: "Liked Door",
    field: "usr",
    sort: "asc",
    width: 100,
  },
  {
    label: "Phone Number",
    field: "phone",
    sort: "asc",
    width: 250,
  },
  {
    label: "Role",
    field: "role",
    sort: "asc",
    width: 100,
  },
  {
    label: "Activated",
    field: "isActivated",
    sort: "asc",
    width: 80,
  },
  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 250,
  },
  {
    label: "SubUsers",
    field: "subUsers",
    width: 150,
  },
  {
    label: "Message Template",
    field: "messageTemplates",
    width: 150,
  },
  {
    label: "Action",
    field: "action",
    width: 80,
  },
];

const superUserColumns = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 250,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 300,
  },
  {
    label: "Linked Door",
    field: "usr",
    sort: "asc",
    width: 100,
  },
  {
    label: "Phone Number",
    field: "phone",
    sort: "asc",
    width: 250,
  },
  {
    label: "Role",
    field: "role",
    sort: "asc",
    width: 100,
  },
  {
    label: "Activated",
    field: "isActivated",
    sort: "asc",
    width: 80,
  },
  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 250,
  },
  {
    label: "Action",
    field: "action",
    width: 80,
  },
  {
    label: "Message Template",
    field: "messageTemplates",
    width: 150,
  },
];

class UserTable extends Component {
  state = {
    loadTable: false,
    userList: [],
    pagination: {},
  };

  componentDidMount() {
    const { users } = this.props;
    if (users && users.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.users !== this.props.users &&
      this.props.users &&
      this.props.users.length
    ) {
      this.showTable(true);
    }
  }

  showTable = (loadTable) => {
    const role = localStorage.getItem("role");
    if (loadTable) {
      const {
        users,
        editUser,
        deleteUser,
        linkMessageTemplate,
        pagination,
      } = this.props;
      const rows = users.map((user, index) => {
        let userRow = { ...user };

        delete userRow["userId"];
        if (userRow.countryCode && userRow.phoneNumber) {
          userRow.phone = userRow.countryCode + userRow.phoneNumber;
        } else {
          userRow.phone = "N/A";
        }

        userRow.usr = userRow.usr !== null ? userRow.usr : "N/A";
        userRow.createdAt = convertDateFormat(
          userRow.createdAt,
          "shortDateTime"
        );
        userRow.role = capitalizeFirstLetter(userRow.role);
        userRow.isActivated = userRow.isActivated ? "Yes" : "No";
        userRow.subUsers = (
          <>
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={() => this.props.history.push(`/subUsers?${user.id}`)}
            >
              Show SubUsers
            </button>
          </>
        );
        userRow.messageTemplates = (
          <>
            <button
              className="mr-2 float-right btn-labeled btn btn-info"
              onClick={linkMessageTemplate.bind(this, userRow)}
            >
              Link msg template
            </button>
          </>
        );
        userRow.action = (
          <>
            <MDBBtn
              onClick={deleteUser.bind(this, userRow.id)}
              tag="a"
              rounded
              size="sm"
              className="px-2 transparentBtn"
            >
              <i className="fas fa-trash mt-0"></i>
            </MDBBtn>
            <MDBBtn
              onClick={editUser.bind(this, userRow)}
              tag="a"
              rounded
              size="sm"
              className="px-2 transparentBtn"
            >
              <i className=" fas fa-pencil-alt mt-0"></i>
            </MDBBtn>
          </>
        );
        return userRow;
      });
      this.setState({
        loadTable,
        userList: {
          columns: role === "super_user" ? superUserColumns : columns,
          rows: rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { changePage } = this.props;
    const { loadTable, userList, pagination } = this.state;

    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort table-pagination recentAlarmTable border-0"
              bordered
              data={userList}
              paging={true}
              scrollX
            />
            {/* <Row className="mt-3 mb-3">
              <Col lg={6}>
                <label className="ml-3">
                  Showing {pagination.currentPage * 10 - 9} to{" "}
                  {pagination.currentPage * 10} of all results
                </label>
              </Col>
              <Col lg={6} className="text-right">
                <Pagination
                  pagination={pagination}
                  changePage={(page) => changePage(page)}
                ></Pagination>
              </Col>
            </Row> */}
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(UserTable);
