import React, { Component } from "react";
import { convertDateFormat } from "../../utils/helperFunctions";
import { MDBBtn, MDBDataTable } from "mdbreact";
import { Row, Col } from "reactstrap";
import Pagination from "../Common/Pagination";
import users from "../../stores/reducers/users";

const superAdminColumn = [
  {
    label: "Account",
    field: "account",
    width: 80,
  },
  {
    label: "Action",
    field: "action",
    sort: "asc",
    width: 100,
  },
  {
    label: "Event",
    field: "event",
    sort: "asc",
    width: 100,
  },
  {
    label: "Message",
    field: "information",
    sort: "asc",
    width: 200,
  },
  {
    label: "Result",
    field: "result",
    sort: "asc",
    width: 100,
  },
  {
    label: "Time",
    field: "time",
    sort: "desc",
    width: 200,
  },
  {
    label: "User",
    field: "user",
    sort: "asc",
    width: 200,
  },
];
class AuditTrailTable extends Component {
  state = {
    loadTable: false,
    logs: [],
    pagination: {},
    role: localStorage.getItem("role"),
  };

  componentDidMount() {
    const { data } = this.props;
    if (data && data.length) {
      this.showTable(true);
    } else {
      this.showTable(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data &&
      this.props.data &&
      this.props.data.length
    ) {
      this.showTable(true);
    }
  }

  showTable = (loadTable) => {
    if (loadTable) {
      const { data, pagination, updateFirmware } = this.props;

      const rows = data.map((item, index) => {
        let gatewayRow = { ...item };
        gatewayRow.account = gatewayRow.account || "N/A";
        gatewayRow.action = gatewayRow.action || "N/A";

        gatewayRow.information = gatewayRow.information || "N/A";
        gatewayRow.user = gatewayRow.user || "N/A";
        return gatewayRow;
      });
      this.setState({
        loadTable,
        logs: {
          columns: superAdminColumn,
          rows: rows,
        },
        pagination,
      });
    } else {
      this.setState({ loadTable });
    }
  };

  render() {
    const { loadTable, logs } = this.state;
    return (
      <React.Fragment>
        {loadTable ? (
          <>
            <MDBDataTable
              className="hideLastColSort recentAlarmTable border-0"
              bordered
              data={logs}
              paging={false}
              scrollX
              displayEntries={true}
              searching={false}
            />
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default AuditTrailTable;
